import React, { Component } from "react";

class About extends Component {
	render() {
		return (
				<section id="about">
		            <div className="container">
		                <div className="row">
		                    <div className="col-md-4">
								<div className="about-box">
		                            <img src="assets/img/people-icon.png" width="62" height="65" alt=""/> 
		                            <h2>2M +</h2>
		                            <p>Scores Analyzed</p>
		                        </div>
		                    </div>
		                    <div className="col-md-6 offset-md-2">
		                    	<h2>About <span>CreditMaestro</span></h2>
		                        <p>Different lenders use different credit scores, CreditMaestro is putting that information in your hands so that you can make the needed changes before you apply.</p>
		                    </div>
		                </div>
		                <div className="row">
		                	<div className="col-md-4 col-sm-12">
								<div className="about-services bd-1">
		                            <img src="assets/img/educate-icon.png" alt=""/>
		                            <h4>What Makes Up Your Credit Score</h4>
								</div>
		                    </div>
		                    <div className="col-md-4 col-sm-12">
		               	    	<div className="about-services bd-2">	
		                            <img src="assets/img/measured-icon.png" alt=""/>
		                        	<h4>Understand How You Are Being Measured</h4>
								</div>
		                    </div>
		                    <div className="col-md-4 col-sm-12">
		               	    	<div className="about-services bd-3">	
		                            <img src="assets/img/credit-icon.png" alt=""/>
		                        	<h4>Understand What’s Driving Your Credit</h4>
								</div>
		                    </div>
		                </div>
		            </div>
		        </section>
			);
	}
}

export default About;
