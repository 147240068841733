import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isValidEmail, minLength } from "../utils/helpers";
import { Auth } from "aws-amplify";
import CMNavbar from "./CMNavbar";
import Footer from "./Footer";
import { getProfilePicture } from '../api/index';
import ScaleLoader from "react-spinners/ScaleLoader";

class SignIn extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      newUser: null,
      confirmationCode: "",
      apiError: "",
      isLoading: false
    };
  }
  componentDidMount(){    
  }

  onEmailChange = e => {
    
    const email = e.target.value;
    this.setState({ email, emailError: isValidEmail(email) ? "" : "Email entered is invalid" });
  };

  onPasswordChange = e => {
    this.setState({
      password: e.target.value,
      passwordError: minLength(this.state.password) ? "" : "Password should be atleast of 8 characters"
    });
  };
  handleKeyPress = (e) =>{
    if (e.key === 'Enter') {
      this.onSignIn();
    }
  };

  onSignIn = () => {
    if (minLength(this.state.password) && !this.state.emailError) {
      this.setState({ isLoading: true });
      Auth.signIn(this.state.email, this.state.password)
        .then(response => {
          let DataAttributes = response.attributes;
          let SubscriptionActive = DataAttributes['custom:subscription_active'];

          if(typeof SubscriptionActive === 'undefined') {
            this.props.history.push("/signup");
          }else{
            this.props.history.push("/dashboard");
          }          
          this.setState({ isLoading: false });          
        })
        .catch(error => {
          this.setState({ isLoading: false });
          if (error.code === "UserNotConfirmedException") {
            Auth.resendSignUp(this.state.email).then(response => {
              this.setState({ newUser: true });
            });
          } else if (error.code === "UserNotFoundException") {
            this.setState({ apiError: "No User is registered with the above email" });
          } else if (error.code === "NotAuthorizedException") {
            this.setState({ apiError: "Password entered is incorrect" });
          } else if (error.code === "PasswordResetRequiredExceptio") {
            this.props.history.push("/forgotPassword");
          } else {
          }
        });
    }else{
      this.setState({
        emailError : 'Please enter email',
        passwordError: 'Please enter password'
      });
    }
  };

  renderSignInForm() {
    const {isLoading} = this.state;
    return (
      <div>
        <CMNavbar anonymous={true} />

        <div className="justify-content-center FormStarting">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  placeholder="Enter Email"
                  onKeyPress={this.handleKeyPress}
                />
                {this.state.emailError && <div className="col-12 error">{this.state.emailError}</div>}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
              </div>            
            </div>            
          </div>
        </div>
        <br></br>
        <br></br>
        <div className=" justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  placeholder="Enter Password"
                  onKeyPress={this.handleKeyPress}
                />
                {this.state.passwordError && (
                  <div className="col-12 error">{this.state.passwordError}</div>
                )}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
              </div>
            </div>            
          </div>
        </div>
        <br></br>
        {this.state.apiError && <div className="row"><div className="col-12 error text-center">{this.state.apiError}<br></br></div></div>}
        <br></br>
        
          
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="text-center">            
              {isLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> :<input type="button" value={`Sign In`} onClick={this.onSignIn} className="btn btn-primary w-100"></input>  }
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
          </div>
        </div>            
          
        <br></br>
        <div className=" justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
            <span>New to Credit-Maestro? </span>
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => {
                this.props.history.push("/signup");
              }}
            >
              Create an Account
            </span>
          </div>
        </div>
        
        <div className=" justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
            <span>Trouble Signing In? </span>
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => {
                this.props.history.push("/forgotpassword");
              }}
            >
              Forgot Password
            </span>
          </div>
        </div>
      </div>
    );
  }

  onOTPChange = e => {
    this.setState({
      confirmationCode: e.target.value
    });
  };

  verifyOtp = event => {
    const that = this;
    event.preventDefault();
    this.setState({ isLoading: true });
    Auth.confirmSignUp(this.state.email, this.state.confirmationCode).then(() => {
      Auth.signIn(this.state.email, this.state.password).then(user => {
        that.props.history.push("/signup");
      }).catch(err => console.log(err));
    }).catch(error => {
      this.setState({ isLoading: false });
    });

  };

  renderConfirmationForm() {
    const {isLoading} = this.state;
    return (
      <div className="">
        <CMNavbar anonymous={true} />

        <div className="row title justify-content-center">Confirm</div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <label>OTP</label>
            <input
              type="number"
              className="inputField"
              value={this.state.confirmationCode}
              onChange={this.onOTPChange}
            />
          </div>
        </div>

        <div className="row">&nbsp;</div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center">
            {isLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> :<input type="button" value={`Continue`} onClick={this.verifyOtp} className="btn btn-primary w-100"></input>  }     
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { newUser } = this.state;
    return (
      <div className="container SignIn">
        {!newUser && this.renderSignInForm()}
        <div className="FooterAnonymous">
          <Footer  anonymous="true"></Footer>
        </div>        
      </div>
    )
  }
}
export default withRouter(SignIn);
