import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Accordion, Card, Button } from 'react-bootstrap';
import { insert } from "../utils/helpers";
import {  PublicRecordStatus } from "../utils/CodeMapping";

class PublicRecordAccordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CurrentAccordionRecordsSelected : -1
        }
    }
    CloseRecordsAddress = (i) => {
        this.props.addressClose();      
        this.setState({ CurrentAccordionRecordsSelected : i });
        this.props.ChangeAccordion(i);
    }
	render() {
        const { PublicRecord } = this.props;
        const { CurrentAccordionRecordsSelected } = this.state;
        return (
            PublicRecord.map((public_record, i) => {
                return (
                    <div key={i}>
                        <Card className={(PublicRecord.length-1) === i ? "LastItem" : null}>
                            <Card.Header  className={CurrentAccordionRecordsSelected === i ? " selectedAccordion" : ""  } onClick={()=> this.CloseRecordsAddress(i)}>
                                <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                                    <div className="row AccordionHeader">
                                        <div className="col-4 text-left">
                                            {public_record.CourtName}
                                        </div>
                                        <div className="col-4 text-center">
                                            {PublicRecordStatus[public_record.Status]}  
                                        </div>
                                        <div className="col-4 text-right">
                                            {insert(insert(public_record.StatusDate, '-', 2), '-', 5)}
                                        </div>
                                    </div>                                    
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={i}>
                                <Card.Body  className={CurrentAccordionRecordsSelected === i ? "AccordionSelected" : ""}>
                                <span className="innerTitle">Public Records </span>
                                    <hr></hr>
                                    <div className="tradeLine">
                                        <div>                                        
                                            <div className="row">
                                                <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                                                    Filing Date
                                                </div>
                                                <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                                                    {insert(insert(public_record.FilingDate, '-', 2), '-', 5)}
                                                </div>
                                            </div>                                            
                                            <div className="row">
                                                <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                                                    Ref # 
                                                </div>
                                                <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                                                    {public_record.ReferenceNumber}
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>                
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </div>
                )
            })
        )
    }
}
export default withRouter(PublicRecordAccordion);
