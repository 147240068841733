import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

class SmallFaq extends Component {
    constructor(props) {
      super(props);
      this.state = {
        FaqSelected: 1,
      }

    }
    FaqClick = (i) => {
		this.setState({
			FaqSelected: i
		});
	}
    
    render(){
        const {FaqSelected} = this.state;
        return(
            <div>                  
                <section id="SmallFaq">	
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">    
                                <h2>Frequently Asked <span>Questions</span></h2>                      
                                <div className="row">&nbsp;</div>
                                <div className="row">&nbsp;</div>
                                <div className={`toggle ${FaqSelected == 1 ? "ToggleOpen" : ""}`}>
                                    <div className="toggle-title" onClick={() => this.FaqClick(1)}>
                                        <h3>                                        
                                            <span className="title-name">What is my Credit Score ?</span>
                                        </h3>
                                    </div>                                             
                                    {FaqSelected == 1 ? <div className="toggle-inner">
                                        <p>A single credit score is a common point of confusion. In actuality, a consumer does not have just one credit score, there are a number of different credit scores. A credit score is merely a numeric output to a credit model. Well then, what is a credit model? (Read the rest after becoming a member, <a href="/#/signup">Join Now!</a>)</p>
                                    </div> : null }
                                </div>
                                <div  className={`toggle ${FaqSelected == 2 ? "ToggleOpen" : ""}`}>
                                    <div className="toggle-title" onClick={() => this.FaqClick(2)}>
                                        <h3>                                        
                                            <span className="title-name">Why is my credit score always different from what my lender tell me it is ?</span>
                                        </h3>
                                    </div>                                    
                                    {FaqSelected == 2 ? <div className="toggle-inner">
                                        <p>The questions around the accuracy of an individual’s credit score is a long-standing battle for both the individual and the prospective lender. More often than not, the individual is monitoring and looking at a different scoring model than what that specific lender is utilizing. (Read the rest after becoming a member, <a href="/#/signup">Join Now!</a>)</p>
                                    </div> : null }
                                </div>
                                <div  className={`toggle ${FaqSelected == 3 ? "ToggleOpen" : ""}`}>
                                    <div className="toggle-title" onClick={() => this.FaqClick(3)}>
                                        <h3>                                        
                                            <span className="title-name">How are credit scores used ?</span>
                                        </h3>
                                    </div>                                    
                                    {FaqSelected == 3 ? <div className="toggle-inner">
                                        <p>Contrary to popular belief, a credit score by itself is not the sole decisioning piece in deciding if a borrower should be approved for any type of loan. (Read the rest after becoming a member, <a href="/#/signup">Join Now!</a>)</p>
                                    </div> : null }
                                    
                                </div>                                                                                                                          
                            </div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <a href="/#/signup" className="main-button">More FAQ</a>
                            </div>
                        </div>
                    </div>
                </section>        
            </div>
        )
    }
}
export default withRouter(SmallFaq);
