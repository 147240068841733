import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class SideMenu extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            CurrentUrl : this.props.location.pathname.substring(1)
        }

    }

    render() {
        const { CurrentUrl } = this.state;
        return(
            <div id="sidebar-wrapper">
                <div>
                    <ul className="navbar-nav sidebar accordion list-group list-group-flush" id="accordionSidebar">
                        <hr className="sidebar-divider my-0"></hr>
                        <li className={"nav-item "}>
                            <a className={"nav-link menu-link-1" + (CurrentUrl==="dashboard" ? " active" : "")} href="/#/dashboard"><span>Home</span></a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link menu-link-2" + (CurrentUrl==="account-profile" ? " active" : "")} href="/#/account-profile"><span>Report</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link menu-link-3" + (CurrentUrl==="faq" ? " active" : "")} href="/#/faq"><span>FAQ</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link menu-link-4" + (CurrentUrl === "glossary" ? " active" : "")} href="/#/glossary"><span>Glossary</span>
                            </a>
                        </li>
                        <hr className="sidebar-divider d-none d-md-block"></hr>
                        {this.state.SideBarMenu}
                    </ul>
                </div>
            </div>
        )
    }
}
export default withRouter(SideMenu);
