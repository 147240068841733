import React, { Component } from "react";
import CMNavbar from "./CMNavbar";
import SideMenu from "./SideMenu";
import { Accordion, Card, Button } from 'react-bootstrap';
import { getFaqData } from '../api/index';
import ReactHtmlParser from 'react-html-parser';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Auth } from "aws-amplify";
import disableScroll from 'disable-scroll';

class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			FaqSelected: -1,
			FaqData: [],
			loading: true,
			NavStatus: false
		}
		Auth.currentAuthenticatedUser({bypassCache: true}).then(data => {
			let data_attributes = data.attributes;
			let subscription_active = data_attributes['custom:subscription_active'];
			if(subscription_active){
				getFaqData().then(response => {
					this.setState({
						FaqData: response,
						loading: false
					});
				});
			}			
		});
		disableScroll.off();
		window.scrollTo(0, 0);		
	}
	componentDidMount(){
	}
	FaqClick = (i) => {
		console.log(i);
		this.setState({
			FaqSelected: i
		});
	}
	onSideMenuChange = (NavStatus) => {
		if(NavStatus){
		  disableScroll.on();
		}else{
		  disableScroll.off();
		}
		this.setState({
		  NavStatus : NavStatus
		})  
	  }

	render() {
		const { FaqSelected, FaqData, loading,NavStatus } = this.state;
		return (

			<div className="Profile MainPage FaqPage" >
				<CMNavbar onSideMenuChange={this.onSideMenuChange} anonymous={false} />
				<div className="navbarSpace"></div>
				<div className="row">
					<div className="col-12">
						<div className={"leftSidebar "+ (NavStatus ? "OpenMobile" : "CloseMobile d-none d-sm-block")}>
							<SideMenu />
						</div>
						<div className="Content">
							<div className="Spacing"></div>
							<div className="row FaqTitle profile-top-bar-no-background">
								Frequently Asked Questions
							</div>
							<div className="Spacing"></div>
							<div className="card shadow mb-4 table-content Faq">
								<div className="loading_sign">
									<ScaleLoader
										sizeUnit={"px"}
										size={150}
										color={'#58b0d3'}
										loading={loading}
									/>
								</div>
								{loading == false ? <Accordion>
									{
										this.state.FaqData.map((item, key) =>
											<Card key={key} className={FaqSelected === key ? "AccordionSelected" : ""} onClick={() => this.FaqClick(key)}>
												<Card.Header>
													<Accordion.Toggle as={Button} variant="link" eventKey={key}>
														{ReactHtmlParser(item.Question)}
													</Accordion.Toggle>
												</Card.Header>
												<Accordion.Collapse eventKey={key}>
													<Card.Body>
														{ReactHtmlParser(item.Answer)}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										)
									}
								</Accordion> : null}
								
							</div>
						</div>
					</div>
				</div>
			</div >

		);
	}
}
export default Faq;
