import React, { Component } from "react";

class Work extends Component {
	render() {
		return (
			<section id="work">
	            <div className="container">
	                <div className="row">
	               	  	<h2 className="text-center">How It <span>Works</span></h2>
					</div>
	                <div className="row">
	                    <div className="col-md-5">
	                        <h3>Monitor Your Credit</h3>
	                      	<p>Monitor the credit score that aligns with your goals and receive the adverse actions that are keeping your score lower than it can be.</p>
	               	  	</div>
	                    
	                    <div className="col-md-1"></div>
	                    <div className="col-md-3">
	               	    	<div className="work-box">	
	                            <img className="img-fluid" src="assets/img/credit-goal-icon.png" alt=""/>
	                        </div>
	                        <h4>Align Your Credit With Your Goals</h4>
						</div>
	                    <div className="col-md-3">
							<div className="work-box">
	                            <img className="img-fluid" src="assets/img/score-insight.png" alt=""/>
							</div>
	                        <h4>Score Insight</h4> 
	                    </div>
	                </div>
	                <div className="row margin-top">
	                	<div className="col-md-5 offset-md-1 order-md-3">
	                        <h3>Your Data Is Secure</h3>
	                      	<p>Security is a CreditMaestro priority. All data is encrypted during both transmission and storage. CreditMaestro does not sell a single piece of your information, so no random credit card or loan offers. As a member of CreditMaestro, its just you and your data to better understand your creditworthiness.</p>
	               	  	</div>
	                    <div className="col-md-3">
	               	    	<div className="work-box">	
	                            <img className="img-fluid" src="assets/img/despute-repoting.png" alt=""/>
	                        </div>
	                        <h4>Dispute Reporting</h4>
						</div>
	                    <div className="col-md-3">
							<div className="work-box">
	                            <img className="img-fluid" src="assets/img/data-img.png" alt=""/>
							</div>
	                        <h4>We Dont Advertise or Sell Your Financial Data</h4> 
	                    </div>
	                </div>
	            </div>
	        </section>
			);
	}
}

export default Work;