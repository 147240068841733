const AccountTypeIndex = {
    "00"    : "Auto Loan",
    "01"	: "Unsecured Loan",
    "02"	: "Secured Loan",
    "03"    : "Partially Secured Loan",
    "04"	: "Horne Improvement Loan",
    "05"	: "FHA Home Improvement Loan",
    "06"	: "Installment Saks Contract",
    "07"	: "Charge Account",
    "08"	: "Real Estate Specific Type Unknown",
    "09"	: "Loan Secured By Cosigner",
    "OA"	: "Time Share Loan",
    "OC"	: "Debt Buyer",
    "OF"	: "Construction Loan",
    "OG"	: "Flexible Spending Credit Card",
    "10"	: "Commercial transaction with personal liability, guarantee or instruction",
    "11"	: "Recreational Merchandise",
    "12"	: "Education Loan",
    "13"	: "Lease",
    "14"	: "Cosigner (Not Borrower)",
    "15"	: "Check Credit Or Line Of Credit",
    "16"	: "FHA Cosigner (Not Borrower)",
    "17"	: "Manufactured Home",
    "18"	: "Credit Card",
    "19"	: "FHA Real Estate Loan",
    "1A"	: "Lender-Placed Insurance",
    "1B"	: "Legitimate Business Purposes",
    "1C"	: "Purchase Of Household Goods",
    "20"    : "Note Loan",
    "21"    : "Note Loan With Cosigner",
    "22"    : "Secured By Household Goods",
    "23"    : "Secured By Household Goods & Other Collateral",
    "25"    : "VA Real Estate Loan",
    "26"    : "Conventional Real Estate Loan, Including Purchase Money First",
    "27"    : "Real Estate Mortgage - With Or Without Other Collateral. Usually A Second Mortgage",
    "29"    : "Rental",
    "2A"    : "Secured Credit Card",
    "2C"    : "Farmer's Home Administration (FMHA)",
    "30"    : "Summary Of Accounts With Same Status",
    "31"    : "Unknown - Credit Extension, Review, Or Collection",
    "33*"   : "Manual Mortgage",
    "37"    : "Combined Credit Plan",
    "3A"    : "Auto Lease",
    "3C*"   : "Licensing - CA And NV Legal Requirement",
    "43"    : "Debit Card",
    "47"    : "Credit Line Secured",
    "48"    : "Collection Department/Agency/Attorney",
    "4D"    : "Telecommunications/Cellular",
    "4F"    : "Tax Collection",
    "50"    : "Family Support",
    "5A"    : "Real Estate-Jr Liens/Non-Purchase Money",
    "5B"    : "Second Mortgage",
    "5C*"   : "Checking Or Savings/Possible Additional Offers",
    "65"    : "Government Unsecured Guaranteed Loan",
    "66"    : "Government Secured Guaranteed Loan",
    "67"    : "Government Unsecured Direct Loan",
    "68"    : "Government Secured Direct Loan",
    "69"    : "Government Grant",
    "6A"    : "Commercial Installment Loan",
    "6B"    : "Commercial Mortgage",
    "6C*"   : "Credit Granting/Possible Additional Offers",
    "6D"    : "Home Equity",
    "70"    : "Government Overpayment",
    "71"    : "Government Fine",
    "72"    : "Government Fee For Service",
    "73"    : "Government Employee Advance",
    "74"    : "Government Miscellaneous Debt",
    "77"    : "Returned Check",
    "78"    : "Installment Loan",
    "7A"    : "Commercial Line Of Credit",
    "7B"    : "Agriculture",
    "7C*"   : "Service Activation/Possible Additional Offers",
    "83"    : "Post Prescreen/Extract Prescreen Inquiry",
    "85"    : "Bi-Monthly Mortgage Payment",
    "86*"   : "Automated Mortgage Report",
    "87"    : "Semi-Monthly Mortgage Payment",
    "89"    : "Home Equity Line Of Credit",
    "8A"    : "Business Credit Card",
    "8B"    : "Deposit Related",
    "8C*"   : "Id Profile",
    "90"    : "Medical Debt",
    "91"    : "Debt Consolidation",
    "92"    : "Utility Company",
    "93"    : "Child Support",
    "94"    : "Spouse Support",
    "95"    : "Attorney Fees",
    "96*"   : "Checking Account",
    "98*"   : "Credit Granting",
    "9A"    : "Secured Home Improvement",
    "9B"    : "Business Line - Personally Guaranteed"
}
const PublicRecordStatus = {
    "00" : "Small claims judgment",
    "01" : "Small claims judgment satisfied",
    "02" : "Small claims judgment vacated or reversed",
    "03" : "Child support delinquency judgment",
    "04" : "Child support delinquency judgment satisfied",
    "05" : "Mechanics lien",
    "08" : "Judgment",
    "09" : "Judgment satisfied",
    "13" : "Bankruptcy Chapter 13-petition filed",
    "14" : "Civil claims judgment vacated or reversed",
    "15" : "Bankruptcy chapter 7-discharged",
    "16" : "Bankruptcy chapter 13-dismissed",
    "17" : "Bankruptcy chapter 13-discharged",
    "18" : "Mechanics lien released",
    "22" : "Bankruptcy chapter 11-dismissed",
    "23" : "Bankruptcy chapter 7-petition filed",
    "24" : "Bankruptcy chapter 7-dismissed",
    "25" : "Bankruptcy chapter 11-petition filed",
    "26" : "Bankruptcy chapter 11-discharged",
    "27" : "Bankruptcy chapter 12-petition filed",
    "28" : "Bankruptcy chapter 12-discharged",
    "29" : "Bankruptcy chapter 12-dismissed",
    "30" : "Fed tax lien",
    "31" : "Federal tax lien released",
    "32" : "State tax lien",
    "33" : "State tax lien released",
    "34" : "County tax lien",
    "35" : "County tax lien released",

}
const TradeLineStatus = {
    "00" : "No condition/status",
    "03" : "Credit card lost or stolen",
    "05" : "Account transferred to another office",
    "07" : "Current account/was delinquent 60 days past due date two times",
    "08" : "Current account/was delinquent 60 days past due date three times",
    "09" : "Current account/was delinquent 60 days past due date four or more times",
    "10" : "Account renewed or refinanced",
    "11" : "This is an account in good standing",
    "14" : "Current account/was 90 days past due date two times",
    "15" : "Current account/was 90 days past due date 3 or more times",
    "16" : "Current account/was 120 days past due date 2 or more times",
    "17" : "Current account/was 150 days past due date 2 or more times",
    "21" : "Consumer reported as deceased",
    "22" : "Account 60 days past due date 2 times",
    "23" : "Account 60 days past due date 3 times",
    "24" : "Account 60 days past due date 4 or more times",
    "25" : "Account 90 days past due date 2 times",
    "26" : "Account 90 days past due date 3 or more times",
    "27" : "Account 120 days past due date 2 or more times",
    "28" : "Account 150 days past due date 2 or more times",
    "29" : "Account 90 days past due date/ was 120 days or more past due date",    
    "31" : "Current account/was 30 days past due date",
    "32" : "Current account/was 30 days past due date two times",
    "33" : "Current account/was 30 days past due date three times",
    "34" : "Current account/was 30 days past due date four times",
    "35" : "Current account/was 30 days past due date five times",
    "36" : "Current account/was 30 days past due date six times or more",
    "37" : "Current account/was delinquent 60 days past due date",
    "38" : "Current account/was delinquent 90 days past due date",
    "39" : "Current account/was delinquent 120 days past due date",
    "40" : "Current account/was delinquent 150 days past due date",
    "41" : "Current account/was delinquent 180 days or more past due date",
    "42" : "Account now redeemed/was a repossession",
    "43" : "Current account/was a collection account, insurance claim or government claim or terminated for default",    
    "45" : "Current account/foreclosure was started",
    "66" : "Credit grantor paid by company who originally sold the merchandise",
    "67" : "Debt included in or discharged through Bankruptcy Chapter 7, 11, or 12",
    "68" : "Account legally paid in full for less than the full balance",
    "69" : "Debt included in or discharged through Bankruptcy Chapter 13",    
    "71" : "Account 30 days past due date",
    "72" : "Account 30 days past due date two times",
    "73" : "Account 30 days past due date three times",
    "74" : "Account 30 days past due date four times",
    "75" : "Account 30 days past due date five times",
    "76" : "Account 30 days past due date six times or more",
    "77" : "Account was delinquent 60 days past due date/now 30 days past due date",
    "78" : "Account delinquent 60 days past due date",
    "79" : "Account was delinquent 90 days past due date/now 30 or 60 days past due date",
    "80" : "Account delinquent 90 days past due date",
    "81" : "Account was delinquent 120 days or more past due date/now 30, 60, or 90 days past due date",
    "82" : "Account delinquent 120 days past due date",
    "83" : "Account delinquent 150 days past due date",
    "84" : "Account delinquent 180 days past due date",
    "85" : "Credit Grantor could not locate consumer/consumer now located",
    "86" : "Now paying/was a charge-off",
    "87" : "Foreclosure proceeding started",
    "88" : "Claim filed with government for insured portion of balance on loan",
    "89" : "Credit grantor received deed for collateral in lieu of foreclosure on a defaulted mortgage",
    "91" : "Early termination by default of original terms of lease or sales contract",
    "92" : "Claim filed for insured portion of balance",
    "93" : "Account seriously past due date/account assigned to attorney, collection agency, or credit grantor's internal collection department",
    "94" : "Credit Grantor reclaimed collateral to settle defaulted mortgage",
    "95" : "Voluntary surrender",
    "96" : "Merchandise was taken back by credit grantor; there may be a balance due",
    "97" : "Unpaid balance reported as a loss by credit grantor",
    "98" : "Credit grantor cannot locate consumer",
    "A1" : "Open account",
    "A2" : "Paid account/zero balance",
    "A3" : "Closed account",
    "A4" : "Inactive account"
}

const MonthShortArray = {
    'January' : 'Jan', 
    'February' : 'Feb', 
    'March' : 'Mar', 
    'April' : 'Apr', 
    'May' : 'May', 
    'June' : 'Jun', 
    'July' : 'Jul', 
    'August' : 'Aug', 
    'September' : 'Sept', 
    'October' : 'Oct', 
    'November' : 'Nov', 
    'December' : 'Dec'
}
export { AccountTypeIndex,PublicRecordStatus,TradeLineStatus,MonthShortArray };











