import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";
import { postSSNUpdate,getZipData } from '../api/index';
import { Auth } from "aws-amplify";
import BarLoader from "react-spinners/BarLoader";
import Select from "react-select";
import LoadingScreen from "./LoadingScreen";
import NumberFormat from 'react-number-format';

class BadSSNAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssn : "",
            addressStreet : "",
            state : "",
            zipcode : "",
            firstName: "",
            lastName : "",
            middleName : "",
            phoneNumber : "",

            bad_ssn : 1,
            bad_address : 1,
            bad_zip : 1,
            bad_firstname : 1,
            bad_lastname : 1,
            bad_middlename : 1,
            bad_phone : 1,

            ZipCodeLoading: false,

            selectedOption: null,

            firstNameError : false,
            lastNameError : false,
            middleNameError : false,
            cityError: false,
            stateError: false,
            zipcodeError: false,
            addressStreetError : false,
            CityDisabled : true,            
            
            CitiesModelDropdown: [
            ],

            pageLoading : true
        }

    }
    componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(data => {            
            let data_attributes = data.attributes;     
            
            if(data_attributes.hasOwnProperty('custom:bad_data')){
                let bad_data = data.attributes['custom:bad_data'];
                if(bad_data == 3){
                    this.props.history.push("/dashboard");
                }                
            }
            if(data_attributes.hasOwnProperty('custom:bad_firstname')){
                let bad_firstname = data.attributes['custom:bad_firstname'];
                this.setState({
                    bad_firstname : bad_firstname
                });
            }            
            if(data_attributes.hasOwnProperty('custom:bad_lastname')){
                let bad_lastname = data.attributes['custom:bad_lastname'];
                this.setState({
                    bad_lastname : bad_lastname
                });
            }
            if(data_attributes.hasOwnProperty('custom:bad_middlename')){
                let bad_middlename = data.attributes['custom:bad_middlename'];
                this.setState({
                    bad_middlename : bad_middlename
                });
            }                
            if(data_attributes.hasOwnProperty('custom:bad_zip')){
                let bad_zip = data.attributes['custom:bad_zip'];
                this.setState({
                    bad_zip : bad_zip
                });
            }                        
            if(data_attributes.hasOwnProperty('custom:bad_ssn')){
                let bad_ssn = data.attributes['custom:bad_ssn'];                
                this.setState({bad_ssn : bad_ssn});
            }
            if(data_attributes.hasOwnProperty('custom:bad_phone')){
                let bad_phone = data.attributes['custom:bad_phone'];
                this.setState({bad_phone : bad_phone});
            }            
            if(data_attributes.hasOwnProperty('custom:bad_address')){
                let bad_address = data.attributes['custom:bad_address'];
                this.setState({bad_address : bad_address});
            }            
            this.setState({
                pageLoading : false
            })
        });
    }
    handleCityChange = (e) => {
        this.setState({ selectedOption: {value : e.value,label : e.value} ,city: e.value })
    }
    onSubmit = () => {
        
        let continue_processing = true;
        let data = {};
        this.setState({
            firstNameError : false,
            lastNameError : false,
            middleNameError : false,
            cityError: false,
            stateError: false,
            zipcodeError: false,
            addressStreetError : false,
            ssnError: false,
            phoneNumberError: false,
        });
        if(this.state.bad_zip == 1){
            if(this.state.zipcode.length == 5){                
                if(!this.state.city){
                    continue_processing = false;
                    this.setState({
                        cityError : "Please enter the city to proceed"
                    });
                }
            }
            else{                
                this.setState({
                    zipcodeError : "Please enter a valid 5 digit zip code to proceed"
                })
            }
        }        
        if(this.state.bad_address == 1){         
            if(!this.state.addressStreet.length){                
                continue_processing = false;                
                this.setState({addressStreetError : "Please enter street address"})
            }
        }
        if(this.state.bad_phone == 1){
            if(this.state.phoneNumber.replace(/[^0-9]/g, '').length != 10){                
                continue_processing = false;                
                this.setState({phoneNumberError : "Please enter a valid 10 digit phone number"})
            }
        }        
        if(this.state.bad_ssn == 1){                        
            if(this.state.ssn.replace(/[^0-9]/g, '').length != 9){                
                continue_processing = false;            
                this.setState({ssnError : "Please enter a valid 9 digital social security number"})
            }
        }
        if(this.state.bad_firstname == 1){
            if(!this.state.firstName.length){
                continue_processing = false;                
                this.setState({firstNameError : "Please enter first name"})
            }
        }
        if(this.state.bad_lastname == 1){
            if(!this.state.lastName.length){
                continue_processing = false;                
                this.setState({lastNameError : "Please enter last name"})
            }
        }
        if(continue_processing){
            let data = {
                ssn : this.state.ssn.replace(/[^0-9]/g, ''),
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                middleName: this.state.middleName,
                addressStreet:this.state.addressStreet,
                phoneNumber: this.state.phoneNumber.replace(/[^0-9]/g, ''),
                zipcode:this.state.zipcode,
                state:this.state.stateValue,
                city: this.state.city
            }
            postSSNUpdate(data).then(response => {
                this.props.history.push("/processing");
            });                  
        }        
    }

    ChangeZipCode = (e) => {        
        let ZipCode = e.target.value;
        if(ZipCode.length == 5){
          this.setState({
            ZipCodeLoading : true
          });
          const data = {
            "zip_code" : e.target.value
          }
          getZipData(data).then(response => {
            if(response.Status){   
                let cities_array = []
                response.Cities.map((key,value) => {            
                  cities_array.push({
                    value: key, 
                    label: key
                  });
                });                 
                this.setState({
                  stateValue : response.State,
                  state : response.StateFullName,
                  CitiesModelDropdown : cities_array,
                  zipcodeError : ""
                });
                if(cities_array.length > 1){
                  this.setState({CityDisabled : false,selectedOption: null});
                }else{
                  this.setState({selectedOption: {value : cities_array[0].value,label : cities_array[0].value} ,city: cities_array[0].value, CityDisabled:true});
                }          
              }else{
                this.setState({
                  zipcodeError : "ZIP Code Not Found"
                });
              }
              this.setState({
                ZipCodeLoading : false,
              });                  
          });
        }
        if(ZipCode.length > 5){
            this.setState({
              zipcode : ZipCode.slice(0, 5)
            });      
        }else{
            this.setState({
              zipcode : ZipCode
            });      
        }
    }
    render() {
        const { bad_zip,bad_address,bad_ssn,bad_middlename,bad_phone,
                bad_firstname,bad_lastname,                
                selectedOption,CitiesModelDropdown,CityDisabled,                                
                zipcodeError,addressStreetError,cityError,ssnError,
                lastNameError,firstNameError,middleNameError,
                phoneNumberError,
                pageLoading
            } = this.state;
            
        return (
            <div>
                <CMNavbar anonymous={false} />
                
                {pageLoading == true ? <div><LoadingScreen/></div> : null}
                {pageLoading == false ? <div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    {bad_firstname == "1" ? 
                    <div>                    
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.firstName}
                                    onChange={e => this.setState({ firstName: e.target.value })}
                                    placeholder="Enter First Name"
                                />                        
                                {firstNameError ? <p className="col-12 error">{firstNameError}</p> : null}
                            </div>
                        </div>                
                        <div className="row">&nbsp;</div>
                    </div> : null}
                    {bad_middlename == "1" ? 
                    <div>                    
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.middleName}
                                    onChange={e => this.setState({ middleName: e.target.value })}
                                    placeholder="Enter Midlde Name"
                                />                        
                                {middleNameError ? <p className="col-12 error">{middleNameError}</p> : null}
                            </div>
                        </div>                
                        <div className="row">&nbsp;</div>
                    </div> : null}
                    {bad_lastname == "1" ? 
                    <div>                    
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.lastName}
                                    onChange={e => this.setState({ lastName: e.target.value })}
                                    placeholder="Enter Last Name"
                                />                        
                                {lastNameError ? <p className="col-12 error">{lastNameError}</p> : null}
                            </div>
                        </div>                
                        <div className="row">&nbsp;</div>
                    </div> : null}
                    {bad_phone == "1" ? 
                    <div>                    
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>Phone Number</label>
                                <NumberFormat format="(###) ###-####" mask="_"
                                    type="text"
                                    className="form-control"
                                    value={this.state.phoneNumber}
                                    onChange={e => this.setState({ phoneNumber: e.target.value })}
                                    placeholder="Enter Phone Number"
                                />                        
                                {phoneNumberError ? <p className="col-12 error">{phoneNumberError}</p> : null}
                            </div>
                        </div>                
                        <div className="row">&nbsp;</div>
                    </div> : null}                
                    {bad_ssn == "1" ? 
                    <div>                    
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>SSN</label>
                                <NumberFormat format="###-##-####" mask="_"
                                    className="form-control"
                                    value={this.state.ssn}
                                    onChange={e => this.setState({ ssn: e.target.value })}
                                    placeholder="Enter SSN"
                                />                              
                                {ssnError ? <p className="col-12 error">{ssnError}</p> : null}
                            </div>
                        </div>                
                        <div className="row">&nbsp;</div>
                    </div> : null}
                    {bad_address == "1" ? 
                        <div>                    
                            <div className="row justify-content-center">                        
                                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <label>Street Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.addressStreet}
                                        placeholder="Enter Street Address"
                                        onChange={e => this.setState({ addressStreet: e.target.value })}
                                    />
                                    {addressStreetError ? <p className="col-12 error">{addressStreetError}</p> : null}
                                </div>                            
                            </div>
                            <div className="row">&nbsp;</div>
                        </div>
                    : null}
                    
                    {bad_zip == "1" ? 
                    <div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>City</label>
                                <Select
                                    value={selectedOption}
                                    className="scoreModelSelect"
                                    onChange={this.handleCityChange}
                                    options={CitiesModelDropdown}
                                    isDisabled={CityDisabled}
                                />            
                                {cityError ? <p className="col-12 error">{cityError}</p> : null}
                            </div>
                        </div>                                    
                        <div className="row">&nbsp;</div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.state}
                                    placeholder="Please enter ZIP Code"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <label>ZipCode</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.zipcode}
                                    placeholder="Enter ZipCode"
                                    onChange={this.ChangeZipCode}       
                                />
                                {zipcodeError ? <p className="col-12 error">{zipcodeError}</p> : null}
                                <BarLoader
                                    size={50}
                                    color={"#123abc"}
                                    loading={this.state.ZipCodeLoading}
                                />
                            </div>
                        </div>
                    </div>
                    : null}
                    {bad_ssn == "1" || bad_address == "1" || bad_zip == "1" || bad_firstname == "1" || bad_lastname == "1" || bad_lastname == "1" || bad_middlename == "1" || bad_phone == "1" ? 
                        <div>
                            <div className="row">&nbsp;</div>
                            <div className="row justify-content-center">                            
                                <input onClick={this.onSubmit} type="button" value="Update" className="btn btn-primary"></input>
                            </div>
                            <div className="row">&nbsp;</div>
                            <div className="row">&nbsp;</div>
                        </div>
                         : 
                        <div className="row justify-content-center">
                            Something has gone wrong, please contact the support team at support@credit-maestro.com
                        </div>
                    }                       
                </div> : null}
            </div>  
        )
    }
}
export default withRouter(BadSSNAddress);
