import React from "react";
import { withRouter } from "react-router-dom";
import {
  Accordion,
  Card,
  Button,
  Popover,
  OverlayTrigger
} from "react-bootstrap";
import {
  AccountTypeIndex,
  TradeLineStatus,
  MonthShortArray
} from "../utils/CodeMapping";
import { insert } from "../utils/helpers";
import NumberFormat from "react-number-format";
import Switch from 'react-ios-switch';

class TradeLineAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentAccordionTradeLineSelected: -1
    };
  }
  componentDidMount() {
    if(this.props.ResetOpenAccordion){
      // console.log("A");
      // this.setState({
      //   CurrentAccordionTradeLineSelected : -1
      // })
      console.log("aniessh");
      console.log(this.state.CurrentAccordionTradeLineSelected);  
    }    
  }
  ConvertToArray = object => {
    var ReturnArray = [];
    Object.keys(object)
      .sort()
      .reverse()
      .forEach(function(year) {
        if (typeof ReturnArray[year] === "undefined") {
          ReturnArray[year] = [];
        }
        var mL = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        var MonthArray = [];
        mL.forEach(function(month) {
          if (typeof MonthArray[month] === "undefined") {
            MonthArray[month] = "";
          }
          if (object[year][month]) {
            MonthArray[month] = object[year][month];
          } else {
            MonthArray[month] = "";
          }
        });
        ReturnArray[year] = MonthArray;
      });
    return ReturnArray;
  };
  mapPaymentPopOverContent(item) {
    switch (item) {
      case "0":
        return "Current with zero balance";
      case "1":
        return "30 days delinquent";
      case "2":
        return "60 days delinquent";
      case "3":
        return "90 days delinquent";
      case "4":
        return "120 days delinquent";
      case "5":
        return "150 days delinquent";
      case "6":
        return "180 days delinquent";
      case "C":
        return "Current";
      default:
        return "-";
    }
  }
  mapPaymentPopOver = item => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>{this.mapPaymentPopOverContent(item)}</Popover.Content>
      </Popover>
    );
    return popover;
  };
  renderTradeLinePaymentMonths = Months => {
    return Object.keys(Months).map(key => {
      return (
        <td key={key}>
          <OverlayTrigger
            trigger="hover"
            placement="auto"
            overlay={this.mapPaymentPopOver(Months[key])}
          >
            <span className="TradeLineButton">
              {this.mapPaymentHistory(Months[key])}
            </span>
          </OverlayTrigger>
          <span className="TradeLineMonth">{MonthShortArray[key]}</span>
        </td>
      );
    });
  };
  renderTradeLinePaymentTable = PaymentHistoryProcessed => {
    if (PaymentHistoryProcessed){
      const PHP = PaymentHistoryProcessed;
      const obj = this.ConvertToArray(PHP);
      return obj.map((key, value) => {
        return (
          <tbody key={value}>
            <tr>
              <td className="title">{value}</td>
              {this.renderTradeLinePaymentMonths(key)}
            </tr>
          </tbody>
        );
      });
    }
  };
  mapPaymentHistory(item) {
    switch (item) {
      case "0":
        return "C";
      case "1":
        return "30";
      case "2":
        return "60";
      case "3":
        return "90";
      case "4":
        return "120";
      case "5":
        return "150";
      case "6":
        return "180";
      case "C":
        return "C";
      default:
        return "-";
    }
  }
  CloseAccountsAddress = i => {
    this.props.addressClose();
    this.setState({ CurrentAccordionTradeLineSelected: i });
    this.props.ChangeAccordion(i);
  };

  render() {
    const { Accounts, CurrentAddress } = this.props;
    const { CurrentAccordionTradeLineSelected } = this.state;
    return Accounts.map((tradeline, i) => {
        var BalanceAmount = tradeline.BalanceAmount;
        BalanceAmount = Number(BalanceAmount).toString();
        var CreditLimitAmount = tradeline.CreditLimitAmount;
        var CreditLimitAmountCheck = CreditLimitAmount;
        
        CreditLimitAmount = Number(CreditLimitAmount).toString();
        return (
          <div key={i}>
 
            <Card
              className={(Accounts.length-1) === i ? "LastItem" : null}
            >
              <Card.Header
                className={
                  CurrentAccordionTradeLineSelected === i
                    ? " selectedAccordion"
                    : ""
                }
                onClick={() => this.CloseAccountsAddress(i)}
              >
                <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                  <div className="row AccordionHeader">
                    <div className="col-4 text-left">
                      {tradeline.SubscriberName}{tradeline.OpenOrClosed}
                    </div>
                    <div className="col-4 text-center">
                      {AccountTypeIndex[tradeline.AccountType]}
                    </div>
                    <div className="col-4 text-right">
                      <NumberFormat
                        value={BalanceAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                        renderText={value => <div>{value}</div>}
                      />
                    </div>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={i}>
                <Card.Body
                  className={
                    CurrentAccordionTradeLineSelected === i
                      ? "AccordionSelected"
                      : ""
                  }
                >
                  <span className="innerTitle">Status</span>
                  <hr></hr>
                  <div className="tradeLine">
                    <div>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                          Date Reported
                        </div>
                        <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                          {tradeline.BalanceDate && (
                            <div>
                              {insert(
                                insert(tradeline.BalanceDate, "-", 2),
                                "-",
                                5
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                          Payment Status
                        </div>
                        <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                          {TradeLineStatus[tradeline.Status]}
                        </div>
                      </div>
                    </div>
                    <div className="row">&nbsp;</div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <span className="innerTitle">Account Details</span>
                        <hr></hr>
                        <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-4">
                            Entity Name
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-9 col-xl-8 innerValue text-right">
                            {tradeline.SubscriberName}
                          </div>
                        </div>
  
                        <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-4">
                            Account Type
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-9 col-xl-8 innerValue text-right">
                            {AccountTypeIndex[tradeline.AccountType]}
                          </div>
                        </div>
  
                        <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-4">
                            Account Opening
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-9 col-xl-8 innerValue text-right">
                            {tradeline.OpenDate && (
                              <div>
                                {insert(
                                  insert(tradeline.OpenDate, "-", 2),
                                  "-",
                                  5
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-4">
                            Current Balance
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-9 col-xl-8 innerValue text-right">
                            <NumberFormat
                              value={BalanceAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                              renderText={value => <div>{value}</div>}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <span className="innerTitle">Payment Details</span>
                        <hr></hr>
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            Date of Last Payment
                          </div>
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 innerValue text-right">
                            {insert(
                              insert(tradeline.LastPaymentDate, "-", 2),
                              "-",
                              5
                            )}
                          </div>
                        </div>
  
                        <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-2 col-xl-4">
                            Term
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-10 col-xl-8 innerValue text-right">                  
                            {isNaN(tradeline.Terms) ? tradeline.Terms : parseInt(tradeline.Terms,10)}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            Payment Frequency
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-8 col-xl-8 innerValue text-right">
                            {tradeline.EnhancedTermsFrequency}
                          </div>
                        </div>
                        {CreditLimitAmountCheck ? <div className="row">
                          <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            Credit Limit
                          </div>
                          <div className="col-6 col-sm-8 col-md-8 col-lg-8 col-xl-8 innerValue text-right">
                            {!isNaN(CreditLimitAmount) ? 
                            <NumberFormat
                              value={CreditLimitAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                              renderText={value => <div>{value}</div>}
                            /> : CreditLimitAmountCheck}
                          </div>
                        </div> : null }
  
                      </div>
                    </div>
  
                    <div className="row">&nbsp;</div>
                    <span className="innerTitle">Leader Info</span>
                    <hr></hr>
                    <div className="leaderInfo">
                      <div className="row">
                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-4">
                          Account Number
                        </div>
                        <div className="col-6 col-sm-8 col-md-8 col-lg-9 col-xl-8 innerValue text-right">
                          {tradeline.AccountNumber}
                        </div>
                      </div>
                    </div>
  
                    <div className="row">&nbsp;</div>
                    <div className="tradeLine hideTradeLine">
                      <div className="table-responsive">
                        <span className="innerTitle">Payment History</span>
                        <hr></hr>
                        <div className="row">&nbsp;</div>
                        <table className="table PaymentHistoryTable">
                          {this.renderTradeLinePaymentTable(
                            tradeline.PaymentHistoryProcessed
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        );
    });
  }
}

export default withRouter(TradeLineAccordion);
