import React, { Component } from "react";
import * as Scroll from 'react-scroll';
import { withRouter } from "react-router-dom";

class Footer extends Component {
	constructor(props) {
        super(props);
        this.AnonymousFooter = this.AnonymousFooter.bind(this);
        this.LoggedInFooter = this.LoggedInFooter.bind(this);
    }
    TakeToScroll = (ScrollElement) => {
        this.props.history.push("/#/");
        var scroller = Scroll.scroller;
        scroller.scrollTo(ScrollElement, {
            duration: 500,        
            smooth: "easeInOutQuint",    
        });        
    }
	AnonymousFooter(){
		return(
			<footer className="">			
				<div className="page-footer footer-bottom">
					<div className="row">
						<div className="col-lg-6 lower_text">
							<p>© 2020 CreditMaestro</p>
						</div>
						<div className="col-lg-6 lower_text">
                            <span className="terms_condition">
                                <a href="/#/terms-conditions">Terms &amp; Condition</a>
                            </span>
							<span className="privacy_policy"> 
                                <a href="/#/privacy-policy">Privacy Policy</a>
                            </span>
						</div>
					</div>
                    <div className="row">
						<div className="col-12 lower_text">

                        </div>
                    </div>
				</div>
			</footer>
		)
	}
	LoggedInFooter(){
		return(
			<footer className="Footer">
                <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <a href="index.html"><img className="logo-footer" src="assets/img/logo.png" alt="" /></a>
                        <p>Your credit information in your hands, take charge of your creditworthiness!</p> 
                    </div>
                    <div className="col-lg-4 offset-lg-1 col-md-4 offset-md-2 QuickLink">
                        <h4>Quick Links</h4>
                        <ul>                        
                            <li><a onClick={() => this.TakeToScroll('home')}>Home</a></li>
                            <li><a onClick={() => this.TakeToScroll('about')}>About</a></li>
                            <li><a onClick={() => this.TakeToScroll('testimonials')}>Users</a></li>
                            <li><a onClick={() => this.TakeToScroll('SmallFaq')}>FAQ</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4>Contact Us</h4>
                        <h6 className="icon-1"><a href="mailto:Info@CreditMaestro.com">Info@CreditMaestro.com</a></h6>
                    </div>
				</div>
			</div>
            <div className="container footer-bottom">
                <div className="row">
                    <div className="col-lg-6">
                        <p>© 2020 CreditMaestro</p>
                    </div>
                    <div className="col-lg-6 text-right">
                    	 <span className="terms_condition"><a href="/#/terms-conditions">Terms &amp; Condition</a></span>
                         <span className="privacy_policy"><a href="/#/privacy-policy">Privacy Policy</a></span>
                    </div>
                    <div className="container-fluid">
                        <span className="footer_text">CreditMaestro is a registered trademark of CreditMaestro. All Rights Reserved. Product name, logo, brands, and other trademarks featured or referred to within CreditMaestro are the property of their respective trademark holders.</span>
                    </div>                    
                </div>
            </div>
		</footer>
		)
	}
	render() {
        const {anonymous} = this.props;      
        return(
            <div>
                {anonymous && this.AnonymousFooter()}
                {!anonymous && this.LoggedInFooter()}      
            </div>

        )
	}
}
export default withRouter(Footer);
