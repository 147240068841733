import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { getProfilePicture } from '../api/index';
import Logo from "../static/images/logo.png";
import ToggleIcon from "../static/images/toggle-icon.png";
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import { Redirect } from "react-router-dom";
import {  Modal } from "react-bootstrap";
import * as Scroll from 'react-scroll';

class CMNavbar extends Component {
    constructor(props) {        
        super(props);
        this.AnonymousNavbar = this.AnonymousNavbar.bind(this);
        this.LoggedInNavbar = this.LoggedInNavbar.bind(this);
        this.toggleSideBar = this.toggleSideBar.bind(this);
        this.state = {
            userName: '',
            userImage: '',
            FirstName : '',
            LastName : '',
            userInitial : '',
            SideBarMenu: true,       
            NavBarWhiteBar : false,
            expanded:  false,

        };              
        window.addEventListener('scroll', this.listenToScroll)
    }    
    listenToScroll = () => {
        if(this.state.expanded){

        }else{
            if(window.pageYOffset > 50){
                this.setState({NavBarWhiteBar : true});
            }else{
                this.setState({NavBarWhiteBar : false});
            }    
        }
        
    }
    FetchProfileImage = () => {
        getProfilePicture().then(response => {
            if(response == 'No Image'){                
            }else{
                this.setState({ userImage: "data:image/png;base64, " + response });
            }                        
        });
    }
    componentDidMount() {
        const userdata = this.props.userdata;
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(data => {    
            let bad_data = data.attributes["custom:bad_data"];            

            let first_name = data.attributes["custom:first_name"];            
            let last_name = data.attributes["custom:last_name"];         
            
            let sub = data.attributes['sub'];            
            let full_name = first_name + " " + last_name;
            let user_initial = "";
            if(typeof data.attributes["given_name"] !== "undefined"){
                user_initial = data.attributes["given_name"].charAt(0)+data.attributes["family_name"].charAt(0)
            }

            this.setState({
                userName: full_name,
                FirstName : first_name,
                LastName : last_name,
                userInitial : user_initial
            });
            if (this.props.anonymous==false && this.state.userName) {
                this.FetchProfileImage();
            }        
        });                      
    }

    toggleSideBar() {
        this.setState({
            SideBarMenu: !this.state.SideBarMenu
        });        
        this.props.onSideMenuChange(this.state.SideBarMenu);            
    }
    onSignOut = () => {
        Auth.signOut().then(response => {
            this.props.history.push("/signin");
        }).catch(err => { });
    }
    ToProfile = () =>{
        this.props.history.push("/profile");
    }
    TakeToScroll = (ScrollElement) => {
        this.props.history.push("/#/");
        var scroller = Scroll.scroller;
        scroller.scrollTo(ScrollElement, {
            duration: 500,        
            smooth: "easeInOutQuint",    
        })        
    }

    LoggedInNavbar() {        

        const { NavBarWhiteBar,expanded,userImage,NotificationModel,ShowMessageFirst,FirstName,LastName,userInitial } = this.state;        

        return (
            <Navbar expanded="true" fixed="top" className="shadow indexup">
                <Navbar.Brand href="#dashboard">
                    <img src={Logo} className="d-none d-sm-block align-top" alt="React Bootstrap logo"></img>
                    <img src={ToggleIcon} onClick={this.toggleSideBar} className="d-block d-sm-none ToggleIcon" alt="toggle"></img>
                </Navbar.Brand>  
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav  className="ml-auto">
                         { userImage || userInitial ? 
                         [userImage ?                          
                             <ul className="navbar-nav ml-auto">    
                                 <li className="nav-item dropdown no-arrow">
                                     <img className="userImage" id="navbarprofileImage" src={userImage}  onClick={this.ToProfile} width="40" height="40" alt="profile"></img>                                    
                                 </li>
                                 <li>
                                     <div className="main-button logout_button" onClick={this.onSignOut}>Logout</div>
                                 </li>                            
                            </ul>
                             :
                             <ul className="navbar-nav ml-auto">    
                                 <li className="nav-item dropdown no-arrow">
                                     <div className="UserIcon circle"><span className="UserName" onClick={this.ToProfile}>{userInitial}</span></div>
                                 </li>
                                 <li>
                                     <div className="main-button logout_button" onClick={this.onSignOut}>Logout</div>
                                 </li>                                
                             </ul>                                
                         ]
                         : null }  
                </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
    NavbarOpen = () => {
        if(this.state.expanded){
            this.setState({
                expanded : false,
                NavBarWhiteBar : false
            })
        }else{
            this.setState({
                expanded : true,
                NavBarWhiteBar : true
            })
        }
    }
    RedirectAndScroll = () => {
        this.props.history.push("/#");
        window.scrollTo(0, 0)
    }
    AnonymousNavbar() {
        const {NavBarWhiteBar,expanded} = this.state;
        return(            
            <Navbar fixed="top" expand="lg" onToggle={this.NavbarOpen} expanded={expanded} className={NavBarWhiteBar ? 'Anonymous shadow indexup' : 'Anonymous indexdown'}>
                <div className="container">
                    <Navbar.Brand onClick={this.RedirectAndScroll}>
                        <img className="logo" src="assets/img/logo.png" alt="logo"/>                    
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link onClick={this.RedirectAndScroll}>Home</Nav.Link>
                            <Nav.Link onClick={() => this.TakeToScroll('about')}>About</Nav.Link>
                            <Nav.Link onClick={() => this.TakeToScroll('work')}>Features</Nav.Link>
                            <Nav.Link onClick={() => this.TakeToScroll('testimonials')}>Users</Nav.Link>
                            <Nav.Link onClick={() => this.TakeToScroll('SmallFaq')}>FAQ</Nav.Link>
                            <Nav.Link href="/#/signin" >Login</Nav.Link>
                            <Nav.Link href="/#/signup" className="main-button">Signup</Nav.Link>                            
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>            
        )
    }
    render() {
        const { anonymous } = this.props;   
        return (                       

            <div className="TopNavbar">{anonymous ? this.AnonymousNavbar() : this.LoggedInNavbar()}</div>

        )
    }
}
export default withRouter(CMNavbar);
