import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";

class AccountBlocked extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount(){
        console.log("a");
    }
    render(){
        return (
            <div>
                <CMNavbar anonymous={false} />
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div class="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                            You have completed the number of retries allowed. Account Blocked. Your account will be automatically deleted. 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AccountBlocked);
