import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";
import { Auth } from "aws-amplify";
import LoadingScreen from "./LoadingScreen";
import { postGetNotifications,postReadNotifications } from '../api/index';
import {  Modal } from "react-bootstrap";

class Processing extends Component {
    constructor(props) {
        super(props);
        this.state ={
            ShowMessageFirst : false,
            NotificationModel : "",
            NotificationRedirect : ""            
        }          
        let CallCount = 0;
        const interval = setInterval(() => {
            postGetNotifications().then(OriginalResponse => {
                let OriginalResponseData = OriginalResponse[0];
                if(OriginalResponse.length){
                    clearInterval(interval);
                    postReadNotifications({ "Date" : OriginalResponseData.Date}).then(response => {
                        if(OriginalResponseData.Message){                
                            this.setState({
                                ShowMessageFirst : true,
                                NotificationModel : OriginalResponseData.Message,
                                NotificationRedirect : OriginalResponseData.Redirect
                            });
                        }else{
                            window.location.href = OriginalResponseData.redirect_to;               
                        }    
                    });                    
                }                        
            });     


            CallCount++;
            if(CallCount == 10){
                clearInterval(interval);
            }
            
        },2000);        
        
        
        
    }
    handleCloseRedirect = () => {
        this.setState({
            ShowMessageFirst : false
        })
        window.location.href = this.state.NotificationRedirect;    
    }
    handleClose = () => {
        this.setState({
            NotificationModel: false,
        });
    }
    render() {
        const {pageLoading,ShowMessageFirst,NotificationModel} = this.state;
        return (
            <div>
                <CMNavbar anonymous={false} />                
                <div className="row text-center">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">                        
                        <div><LoadingScreen/></div>
                        <div className="row">&nbsp;</div>
                        <div className="row">&nbsp;</div>
                        <div className="row">
                            <div className="col-12 text-center">
                                This may take a moment... We are confirming your identity and then retrieving your personal information.
                            </div>
                        </div>
                        <Modal show={ShowMessageFirst} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Notification</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                        {NotificationModel}
                                    </div>
                                </div>                      
                                <div class="row">&nbsp;</div>
                                <div class="row">&nbsp;</div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                        <input class="btn btn-primary" onClick={this.handleCloseRedirect} value="Close"></input>                      
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>                
            </div>
        )
    }
}
export default withRouter(Processing);
