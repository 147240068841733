import React, { Component } from "react";
import { Redirect,withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";

class PrivacyPolicy extends Component {
  constructor(props) {
		super(props);
		this.state = {
	
        }
        window.scrollTo(0, 0)
    }
    renderPrivacyPolicy(){
        return(
            <div className="container">
                <div className="TCPage">
                <p><strong>CreditMaestro is committed to your privacy.</strong></p>
                    <p>Our goal is to maintain your trust and confidence when handling personal and financial information about you.</p>
                    <p><strong>What this policy covers</strong></p>
                    <p>This CreditMaestro Privacy Policy (&ldquo;Privacy Policy&rdquo;) describes how we collect information when you visit or use CreditMaestro&rsquo;s websites, mobile applications, and other online services that link to this Privacy Policy (&ldquo;Services&rdquo;). It also describes how we use and share such information and explains your privacy rights and choices. Our Services are intended for a U.S. audience. The terms &ldquo;CreditMaestro,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo; mean CreditMaestro and its U.S. affiliates. &ldquo;You&rdquo; means an individual who visits our Services and does not refer to a business or other entity or to individuals outside the U.S.</p>
                    <p><strong>Collecting, Using, and Sharing Information</strong></p>
                    <p>We collect information in a variety of contexts. For example, we may collect information:</p>
                    <ul>
                    <li>Directly from you. We collect information directly from you, such as when you register for our services, use our mobile apps, communicate with us, respond to surveys, provide feedback, or enter contests or promotions.</li>
                    <li>Automatically when you use our Online Services. We and others on our behalf may collect Device Data, Online/Mobile Activity Data, and other information automatically when you interact with us online.</li>
                    </ul>
                    <p>This Privacy Policy applies to information we collect when you use our Services. We may combine that information with information we collect in other contexts, such as from our phone calls and emails with you, from third-party data sources for fraud prevention, identity verification, or marketing purposes, and from publicly available data sources. We will treat such combined information in accordance with this Privacy Policy.</p>
                    <p>Depending on how you interact online with us, we may collect various types of information. For example, when you sign up for or use a service, we may collect:</p>
                    <ul>
                    <li>Contact or identity data, such as your name, mailing address, email address, phone number, date of birth, government-issued identifier (e.g., Social Security number, tax ID number, driver&rsquo;s license, or other government ID), citizenship, username and password, profile picture, and other information that directly identifies you.</li>
                    <li>Account-related data, such as account number, credit/debit card number, account history, account balances, loan details, vehicle or property information, information about beneficiaries and joint account holders, business-related information (e.g., business name, address, revenue, and industry type), and other information related to you.</li>
                    <li>Demographic data, such as gender, marital status, age, household size/composition, education level, income, occupation, and employment status.</li>
                    </ul>
                    <p>When you browse our website or use our mobile apps, we may also collect:</p>
                    <ul>
                    <li>Device data, such as your device type, web browser type and version, operating system type and version, display/screen settings, language preferences, internet protocol address, mobile network information, general location (e.g., city, state, or country), cookie IDs, device IDs, mobile advertising IDs (e.g., Apple&rsquo;s IDFA or Google&rsquo;s Advertising ID), and likely connections among different browsers and devices that you use (collectively, &ldquo;Device Data&rdquo;).</li>
                    <li>Online/mobile activity data, such as login data, search history, information about how you use and interact with our Online Services or advertising (including content viewed, links clicked, and features used), when and how often you use our Online Services, the webpage from which you clicked a link to come to our Online Services (e.g., the referrer URL), and crash reports (collectively, &ldquo;Online/Mobile Activity Data&rdquo;).</li>
                    <li>Marketing data, such as your marketing preferences, information about products or services we think you might like, and inferences based on your interactions with us or our partners (e.g., Online/Mobile Activity Data used for targeted advertising).</li>
                    <li>Communications data, such as your communication preferences and details or the content of your communications with us (e.g., chat messages).</li>
                    <li>Survey and research data, such as your responses to questionnaires, surveys, requests for feedback, and research activities.</li>
                    </ul>
                    <p>If you disclose any information relating to other people to us or to our service providers when you visit or use our Services, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>
                    <p>We use information for various purposes depending on how you interact with us. For example, we may use your information for the purposes described below:</p>
                    <ul>
                    <li>Providing, maintaining, and servicing your accounts, such as enabling you to obtain our services, evaluating your eligibility for a CreditMaestro service, servicing and managing your accounts, providing customer service, communicating with you, and providing online tools and features.</li>
                    <li>Processing transactions and or payments for fulfilling membership orders.</li>
                    <li>Verifying your identity, such as conducting identity verification when you apply for our services, authenticating your login credentials, verifying your location to allow access to your account, and storing security questions for subsequent verification online or over the phone.</li>
                    <li>Detecting and preventing fraud, such as determining fraud risk and identifying fraudulent transactions.</li>
                    <li>Protecting against security risks, such as monitoring network activity logs, detecting security incidents, conducting data security investigations, and otherwise protecting against malicious, deceptive, fraudulent, or illegal activity.</li>
                    <li>Advertising and marketing for services via mail, email, or text message, displaying online advertising, targeting our offers or promotions, conducting market research, and evaluating or improving the effectiveness of our marketing efforts.</li>
                    <li>Conducting analytics and research, such as examining which parts of our website you visit or which aspects of our mobile apps you find most useful, evaluating user interface and experiences, testing features or functionality, performing debugging and error repair, and analyzing the use of our Services.</li>
                    <li>Improving our services, such as personalizing and optimizing your website and mobile experiences, recognizing you across different browsers and devices you use, improving existing services, and developing new services.</li>
                    <li>Carrying out legal and business purposes, such as complying with applicable laws, responding to civil, criminal, or regulatory lawsuits, subpoenas, or investigations, exercising our rights or defending against legal claims (including for collections and recoveries on past-due accounts), resolving complaints and disputes, performing compliance activities, analyzing credit risk, conducting credit reporting activities, regulatory reporting, performing institutional risk control, conducting human resources activities, and otherwise operating, managing, and maintaining our business.</li>
                    <li>Using aggregated and de-identified information, such as using or modifying the information described in this Privacy Policy in a manner that does not allow us to reasonably identify you. For example, we may compile aggregated statistics to understand trends or to research the percentage of users accessing a specific website feature. Information that has been aggregated and de-identified is no longer subject to this Privacy Policy.</li>
                    </ul>
                    <p>We share information in a variety of contexts. For example, we may share your information with:</p>
                    <ul>
                    <li>Affiliates. We may share your information with companies in the CreditMaestro family.</li>
                    <li>Service providers. We use other companies to provide services on our behalf and to help us run our business. We may share information with these service providers, or they may collect information on our behalf, for various business purposes. For example, we use service providers for hosting and securing our information systems, servicing customer accounts, detecting and preventing fraud, assisting with human resources activities, communicating with our customers, analyzing and improving our Services, and targeting our advertising.</li>
                    <li>Credit bureaus. We share information with credit reporting agencies, such as Experian, Transunion, and Equifax, to report on or learn about your financial circumstances and for other lawful purposes.</li>
                    <li>Government entities and others with whom we share information for legal or necessary purposes. We share information with government entities and others for legal and necessary purposes, such as:</li>
                    <ul>
                    <li>To respond to requests from our regulators or to respond to a warrant, subpoena, governmental audit or investigation, law enforcement request, legal order, or other legal process.</li>
                    <li>To facilitate a merger, acquisition, sale, bankruptcy, or other disposition of some or all of our assets, in which case we may transfer your information to the relevant third party.</li>
                    <li>For other legal purposes, such as to enforce our terms and conditions, exercise or defend legal claims, or if we determine that disclosure is necessary or appropriate to protect the life, safety, or property of our customers, ourselves, or others.</li>
                    </ul>
                    <li>Recipients of aggregated and de-identified information. We may share aggregated and de-identified information (such as aggregated statistics regarding the use of our financial products and services) with third parties for any purpose.</li>
                    </ul>
                    <p>We and third-party providers acting on our behalf use a variety of online tools and technologies to collect information when you visit or use the Services, including Device Data and Mobile/Online Activity Data. For example, we use these tools to collect information for debugging, fraud prevention, session management, and other necessary purposes. We also use these to conduct personalization, analytics, and targeted advertising on or through the Services.</p>
                    <p>These tools include:</p>
                    <ul>
                    <li>Server logs. Server logs automatically record information and details about your online interactions with us. For example, server logs may record information about your visit to our website on a particular time and day.</li>
                    <li>Cookies. Cookies are small text files that a website&rsquo;s server stores in your web browser. Cookies allow companies to recognize your device and store information about your account and preferences. For example, we may use cookies to store information about pages visited on our sites, language preferences, your relationship with us, or other information that we have associated with you or your device.</li>
                    <li>Pixel tags. A pixel tag (also known as a web beacon, clear GIF, pixel, or tag) is an image or a small string of code that may be placed in a website, advertisement, or email. It allows companies to set or read cookies or transfer information to their servers when you load a webpage or interact with online content. For example, we or our service providers may use pixel tags to determine whether you have interacted with a specific part of our website, viewed a particular advertisement, or opened a specific email.</li>
                    <li>SDKs and mobile advertising IDs. Our mobile applications may include third-party software development kits (&ldquo;SDKs&rdquo;) that allow us and our service providers to collect information about your mobile app activity. In addition, some mobile devices come with a resettable advertising ID (such as Apple&rsquo;s IDFA and Google&rsquo;s Advertising ID) that, like cookies and pixel tags, allow us and our service providers to identify your mobile device over time for advertising purposes.</li>
                    <li>Third-party plugins. Our Services may include plugins from other companies, including social media companies (e.g., the Facebook &ldquo;Like&rdquo; button). These plugins may collect information, such as information about the pages you visit, and share it with the company that created the plugin even if you do not click on the plugin. These third-party plugins are governed by the privacy policies and terms of the companies that created them.</li>
                    </ul>
                    <p>We and third-party providers acting on our behalf use online tracking technology to conduct personalization, analytics, and targeted advertising on or through the Services.</p>
                    <ul>
                    <li>Personalization. CerditMaestro may customize content and advertisements for our services on our own and third-party websites and mobile apps. In order to make the content and advertising as informative and useful as possible, we may use the information we collect about you online, alone or in combination with information about your relationship with us (such as types of accounts, transactional information, or the state in which you have accounts). We also use online tracking technologies to recognize your computer or device when you&rsquo;re logged in, enable enhanced features or functionality, improve usability, and otherwise provide the Services to you.</li>
                    <li>Analytics. We and our third-party providers use online tracking technologies to engage in data analytics, auditing, measurement, research, reporting, and debugging on our Services and to measure the effectiveness of our advertising. For example, we use Google Analytics on our Services for such purposes.</li>
                    <li>Targeted advertising. We and our third-party providers may collect information about your activities on our Services and across different websites, mobile apps, and devices over time for targeted advertising purposes. These providers may then show you ads, including across the internet and mobile apps, and other devices, based in part on the information they have collected.</li>
                    </ul>
                    <p>For example, when you visit the CreditMaestro website and explore our Services, our advertising providers may use that information to determine which ads to show you when you go to other, non-CreditMaestro websites. Similarly, when you view a CreditMaestro ad on your computer, our advertising providers may use that information when deciding whether to show you a subsequent ad on your laptop or mobile device.</p>
                    <p><strong>Exercise other privacy rights or choices</strong></p>
                    <p>You may have additional rights under applicable law, such as the California Consumer Privacy Act for California residents, or we may provide you with additional choices to access, delete, or otherwise manage certain personal information. For more information about these rights or choices and how to exercise them, please contact us.</p>
                    <p><strong>Keeping Information Secure</strong></p>
                    <p>At CreditMaestro, we care about your safety and security. We have an information security program that includes administrative, technical, and physical measures to protect information within our company. While we strive to protect your information, no method of data transmission or storage is 100% secure, and we cannot ensure or warrant the security of your information.</p>
                    <ul>
                    <li>If you suspect a website or mobile app is pretending to be from CreditMaestro (also known as &ldquo;spoofing&rdquo;), do not enter personal information. Instead, contact us immediately via email at <u>abuse@creditmaestro.com</u>.</li>
                    <li>If you receive an email that claims to be from us, but you are not sure or think it is suspicious, do not click on any of the links in the email. Instead, forward it to us at <u>abuse@creditmaestro.com</u> and delete it.</li>
                    <li>If you have reason to believe that your interaction with us is no longer secure, please immediately notify us at <u>abuse@creditmaestro.com.</u></li>
                    </ul>
                    <p><strong>Children's Privacy</strong></p>
                    <p>Our Online Services are not directed to individuals under 18. We also do not knowingly collect personal information from children under 18 through our Services. We kindly request that children do not provide information to us through our Services.</p>
                    <p><strong>Social Media</strong></p>
                    <p>CreditMaestro has official pages on social media services such as Facebook and Instagram. When you interact with us on these pages or elsewhere on social media, we may collect information such as your likes, interests, feedback, and preferences. We may collect additional information from social media companies if you choose to share with them and they, in turn, share such information with us.</p>
                    <p>Any posts you make on our official social media pages -- including posts that contain pictures, comments, suggestions, opinions, complaints, or personal information -- are available to others who use those pages. Never include sensitive personal, financial, or other confidential information such as your Social Security number, account number, phone number, mailing address, or email address when posting or commenting online. Please refer to the privacy policies of our social media partners when you interact with them online.</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        )
    }    
    render() {
        return(
            <div>
                <CMNavbar anonymous={true} />
                {this.renderPrivacyPolicy()}
            </div>
        )
    } 
}

export default withRouter(PrivacyPolicy);