import React, { Component } from "react";
import { Redirect,withRouter } from "react-router-dom";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SignUpEmail : '',
		}
	}
	SignUp = () => {
		this.props.history.push({
			pathname:"/signup",
			state:{ SignUpEmail:this.state.SignUpEmail }
		});
	}
	render() {
		const {SignUpEmail} = this.state;
		return (
			<div>				
				<div id="home" className="banner">				
					<div className="container SignUpBlock">
						<div className=" row">
							<div className="col-md-6 right-container-banner-resposive">
								<img className="img-fluid" src="assets/img/header-illustration.png" alt="banner-img" />
							</div>
							<div className="col-md-6 resposive-padding-top">
								<h2>Control Your Credit Score,<span>Don’t Let It Control You!</span></h2>
								<p>You don’t just have one credit score, you have many. It’s about time that you know which score your lender is using before they make their lending decision. Become a member and start monitoring your credit score today for only $9.99/month.</p>
								<fieldset>
									<input 
									type="email" value={SignUpEmail} 
									className="form-control" placeholder="Enter your email here"
									onChange={e => this.setState({ SignUpEmail: e.target.value })}
									></input>
									<button className="SignUp" onClick={this.SignUp}>Sign Up</button>
								</fieldset>
							</div>
							<div className="col-md-6 right-container-banner">
								<img className="img-fluid" src="assets/img/header-illustration.png" alt="banner-img" />
							</div>
						</div>
					</div>
				</div>
			</div>
			
		);
	}
}
export default withRouter(Home);
