import React, { Component } from "react";
import CMNavbar from "./CMNavbar";
import Home from "./Home";
import About from "./About";
import Work from "./Work";
import Testimonials from "./Testimonials";
import SmallFaq from "./SmallFaq";
import Homesignup from "./Homesignup";
import Footer from "./Footer";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

class HomePage extends Component {
  constructor(props) {
    super(props);
    console.log("A");
    Auth.currentAuthenticatedUser({
      bypassCache: true
    }).then(data => {   
      this.props.history.push("/dashboard");
    }).catch( err => {
      // this.props.history.push("/signin");
    });
  }
  login = () => {
    this.props.history.push("/signin");
  };

  render() {
    return (
      <div className="AnonymousBody">
      
        <CMNavbar anonymous={true}/>
        
        <Home name="home" />

        <About name="about"/>

        <Work name="work" />
    
        <Testimonials name="testimonials"  />

        <SmallFaq name="faq"  />

        <Homesignup />

        <Footer />
      </div>
    );
  }
}

export default withRouter(HomePage);
