import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import config from "../config";
import { postStripeData, postStripeSessionData,getZipData } from "../api/index";
import { Auth } from "aws-amplify";
import Select from "react-select";
import BarLoader from "react-spinners/BarLoader";
import NumberFormat from 'react-number-format';
import ScaleLoader from "react-spinners/ScaleLoader";

class AttributesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: this.props.ExistingData["given_name"],
      middlename: this.props.ExistingData["middle_name"],
      lastname: this.props.ExistingData["family_name"],
      ssn: this.props.ExistingData["custom:ssn"],
      zipcode: this.props.ExistingData["custom:zipcode"],
      address: this.props.ExistingData["custom:contactAddress"],
      city: this.props.ExistingData["custom:city"],
      state: this.props.ExistingData["custom:state"],
      stateValue : "",
      phone: this.props.ExistingData["phone_number"],
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
      selectedOption: null,
      CityDisabled : true,
      firstNameError: false,
      middleNameError: false,
      lastNameError: false,
      addressError: false,
      cityError: false,
      stateError: false,
      zipcodeError: false,
      ssnError: false,
      phoneError: false,
      ZipCodeLoading : false,
      isLoading : false,
      CitiesModelDropdown: [
      ],
    };
  }
  passToCheckoutRediredct = async session_id => {
    const stripePromise = loadStripe(config.credit_maestro.STRIPE_ID);
    const stripe = await stripePromise;
    const { error } = stripe.redirectToCheckout({
      sessionId: session_id
    });
  };
  passToCheckout = async () => {
    postStripeSessionData(this.state.accessToken).then(session_id => {
      this.passToCheckoutRediredct(session_id);
    });
  };
  NonMandatoryAllLetters = inputtxt => {
    if (typeof inputtxt === "undefined") {
      return true;
    }
    if (inputtxt.length == 0) {
      return true;
    } else {
      if (typeof inputtxt !== "undefined") {
        var letters = /^[A-Za-z]+$/;
        if (inputtxt.match(letters)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };
  allLetters = inputtxt => {
    if (typeof inputtxt !== "undefined") {
      var letters = /^[A-Za-z]+$/;
      if (inputtxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  allSSNNumbers = inputtxt => {
    if (typeof inputtxt !== "undefined") {
      var letters = /^\d{9}$/;
      let ssn_validation = inputtxt.replace(/[^0-9]/g, '');
      if (ssn_validation.match(letters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  allPhoneNumbers = inputtxt => {
    if (typeof inputtxt !== "undefined") {
      var letters = /^\d{10}$/;
      inputtxt = inputtxt.substring(2);
      let phone_number_validation = inputtxt.replace(/[^0-9]/g, '');
      if (phone_number_validation.match(letters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  zipVerification = inputtxt => {
    console.log(inputtxt);
    if (typeof inputtxt !== "undefined") {
      var letters = /^\d{5}$/;
      if (inputtxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  addressVerification = inputtxt => {
    if (typeof inputtxt !== "undefined") {
      var letters = /^[#.0-9a-zA-Z\s,-]+$/;
      if (inputtxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  stateVerification = inputtxt => {
    if (typeof inputtxt !== "undefined") {
      var letters = /^[A-Z]{2}$/;
      if (inputtxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  handleRegisterPress = (e) =>{
    if (e.key === 'Enter') {
    this.onSubmit();
    }
  };
  onSubmit = async () => {
    const {
      firstname,
      middlename,
      lastname,
      city,
      state,
      stateValue,
      zipcode,
      ssn,
      address,
      phone
    } = this.state;
    this.setState({isLoading : true});
    const payload = {
      "name" : firstname + " " + lastname,
      "given_name": firstname,      
      "family_name": lastname,
      "phone_number" : typeof phone !== "undefined" ? "+1"+phone.replace(/[^0-9]/g, '') : "",
      "custom:ssn": typeof ssn !== "undefined" ? ssn.replace(/[^0-9]/g, '') : "",
      "custom:zipcode": zipcode,
      "custom:contactAddress": address,
      "custom:city": city,
      "custom:state": stateValue,      
    };
    if(typeof payload["middle name"] !== "undefined"){
      payload["middle_name"] = middlename;
    }
    this.setState({
      firstNameError: !this.allLetters(payload["given_name"]),
      lastNameError: !this.allLetters(payload["family_name"]),
      middleNameError: !this.NonMandatoryAllLetters(
        payload["middle_name"]
      ),
      ssnError: !this.allSSNNumbers(payload["custom:ssn"]),
      phoneError: !this.allPhoneNumbers(payload["phone_number"]),
      addressError: !this.addressVerification(payload["custom:contactAddress"]),
      stateError: !this.stateVerification(payload["custom:state"]),
      cityError: !this.addressVerification(payload["custom:city"]),
      zipcodeError: !this.zipVerification(payload["custom:zipcode"])
    });    
    const user = await Auth.currentAuthenticatedUser();
    if(this.allLetters(payload["given_name"]) 
        && this.allLetters(payload["family_name"]) 
        && this.NonMandatoryAllLetters(payload["middle_name"])
        && this.zipVerification(payload["custom:zipcode"])
        && this.addressVerification(payload["custom:contactAddress"])
        && this.allSSNNumbers(payload["custom:ssn"])
        && this.allPhoneNumbers(payload["phone_number"])
    ){
      const user = await Auth.currentAuthenticatedUser();
      Auth.updateUserAttributes(user, payload).then(response => {
        this.passToCheckout();
      }).catch(error => {
        this.setState({isLoading : false});
      });
    }else{
      this.setState({isLoading : false});
    }    
  };
  ChangeZipCode = (e) => {        
    let ZipCode = e.target.value;
    if(ZipCode.length == 5){
      this.setState({
        ZipCodeLoading : true
      });
      const data = {
        "zip_code" : e.target.value
      }
      getZipData(data).then(response => {
        if(response.Status){   
          let cities_array = []
          response.Cities.map((key,value) => {            
            cities_array.push({
              value: key, 
              label: key
            });
          });                 
          this.setState({
            stateValue : response.State,
            state : response.StateFullName,
            CitiesModelDropdown : cities_array,
            zipcodeError : ""
          });
          if(cities_array.length > 1){
            this.setState({CityDisabled : false,selectedOption: null});
          }else{
            this.setState({selectedOption: {value : cities_array[0].value,label : cities_array[0].value} ,city: cities_array[0].value });
          }          
        }else{
          this.setState({
            zipcodeError : "ZIP Code Not Found"
          });
        }
        this.setState({
          ZipCodeLoading : false,
        });                        
      });
    }    
    if(ZipCode.length > 5){
      this.setState({
        zipcode : ZipCode.slice(0, 5)
      });      
    }else{
      this.setState({
        zipcode : ZipCode
      });      
    }
  }
  handleCityChange = (e) => {
    
    this.setState({ selectedOption: {value : e.value,label : e.value} ,city: e.value })
  }
  render() {
    const {
      selectedOption,
      firstNameError,
      middleNameError,
      lastNameError,
      ssnError,
      phoneError,
      addressError,
      stateError,
      cityError,
      CityDisabled,
      zipcodeError,
      CitiesModelDropdown,
      isLoading
    } = this.state;
    return (
      <div className="formContainer CreditCard">
        <div className="row title justify-content-center">Fill Details</div>

        <div className="row justify-content-center">
          <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5">
            <label>First Name</label>
            <input
              onKeyPress={this.handleRegisterPress}
              type="text"
              className="form-control"
              value={this.state.firstname}
              onChange={e => this.setState({ firstname: e.target.value })}
            />
            {firstNameError ? (
              <p className="col-12 error">
                First Name is required and has to be alphabets only
              </p>
            ) : null}
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 SecondLineForm">
            <label>Middle Initial</label>
            <input
              onKeyPress={this.handleRegisterPress}
              type="text"
              className="form-control"
              value={this.state.middlename}
              onChange={e => this.setState({ middlename: e.target.value })}
            />
            {middleNameError ? (
              <p className="col-12 error">Middle Name has to be alphabets only
              </p>
            ) : null}
          </div>
          <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 SecondLineForm">
            <label>Last Name</label>
            <input
              onKeyPress={this.handleRegisterPress}
              type="text"
              className="form-control"
              value={this.state.lastname}
              onChange={e => this.setState({ lastname: e.target.value })}
            />
            {lastNameError ? (
              <p className="col-12 error">
                Last Name is required and has to be alphabets only
              </p>
            ) : null}
          </div>
        </div>

        <div className="row">&nbsp;</div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <label>Address</label>
            <input
              onKeyPress={this.handleRegisterPress}
              className="form-control"
              value={this.state.address}
              onChange={e => this.setState({ address: e.target.value })}
              placeholder={CitiesModelDropdown.length ? "" : "Please enter Street Address"}              
            />
            {addressError ? (
              <p className="col-12 error">Address is required</p>
            ) : null}
          </div>

          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 SecondLineForm">
            <label>City</label>
            <Select
              value={selectedOption}
              className="scoreModelSelect"
              onChange={this.handleCityChange}
              options={CitiesModelDropdown}
              isDisabled={CityDisabled}
            />            
          </div>
        </div>

        <div className="row">&nbsp;</div>

        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <label>State</label>
            <input
              onKeyPress={this.handleRegisterPress}
              type="text"
              className="form-control"
              value={this.state.state}
              onChange={e => this.setState({ state: e.target.value })}
              disabled
            />
            {stateError ? <p className="col-12 error">State is required</p> : null}
          </div>

          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 SecondLineForm">
            <label>Zip Code</label>
            <input
              onKeyPress={this.handleRegisterPress}
              type="text"
              className="form-control"
              value={this.state.zipcode}
              onChange={this.ChangeZipCode}              
            />
            {zipcodeError ? <p className="col-12 error">{zipcodeError}</p> : null}
            <BarLoader
                size={50}
                color={"#123abc"}
                loading={this.state.ZipCodeLoading}
              />
          </div>
        </div>
        <div className="row">&nbsp;</div>

        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <label>Social Security Number</label>
            <NumberFormat format="###-##-####" mask="_"
              className="form-control"
              value={this.state.ssn}
              onChange={e => this.setState({ ssn: e.target.value })}
              onKeyPress={this.handleRegisterPress}
            />            
            {ssnError ? (
              <p className="col-12 error">SSN has to be 9 digit number</p>
            ) : null}
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 SecondLineForm">
            <label>Phone</label>            
            <NumberFormat format="(###) ###-####" mask="_"
            className="form-control"
              value={this.state.phone}
              onChange={e => this.setState({ phone: e.target.value })}
              onKeyPress={this.handleRegisterPress}
            />
            {phoneError ? (
              <p className="col-12 error">Phone number has to be 10 digit number</p>
            ) : null}
          </div>
        </div>
        <div className="row">&nbsp;</div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
            {isLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> : <input type="btn btn-primary w-100" className="btn btn-primary w-100" value="Sign Up" onClick={this.onSubmit} />}
          </div>
        </div>
      </div>
    );
  }
}

export default AttributesForm;
