import React from "react";
import "./sass/style.scss";
import "./sass/style.css";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import HomePage from "./components/HomePage";
import Profile from "./components/Profile";
import AccountBlocked from "./components/AccountBlocked";

import AccountProfile from "./components/AccountProfile";
import Pusher from 'pusher-js';
import config from "./config";
import Processing from "./components/Processing";
import BadSSNAddress from "./components/BadSSNAddress";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";


import Faq from "./components/Faq";
import Glossary from "./components/Glossary";
import MetaTags from 'react-meta-tags';
import { Auth } from "aws-amplify";
import { HashRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import { getUserAttributes } from "./utils/helpers";
import ProtectedRoute from './components/ProtectedRoute';
class App extends React.Component {
  InitPusher = (userName) => {
    const pusher = new Pusher(config.pusher.APP_ID, {
        cluster: config.pusher.CLUSTER,
    });
  }
  constructor(props) {
    super(props);    
  }
  render() {
    return (
      <Router>
        
        <div className="app">
          <div className="screen">
            <div className="container-fluid Overall">
              <MetaTags>
                <meta name="robots" content="noindex" />
              </MetaTags>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/signin" component={() => <SignIn />} />
                <Route path="/signup" component={() => <SignUp />} />
                <Route path="/forgotpassword" component={() => <ForgotPassword />} />
                <Route path="/dashboard" component={() => <Dashboard />} />
                <Route path="/dashboard" component={() => <Dashboard />} />
                <Route path="/profile" component={() => <Profile />} />
                <Route path="/account-profile" component={() => <AccountProfile />} />

                <Route path="/faq" component={() => <Faq />} />
                <Route path="/glossary" component={() => <Glossary />} />
                <Route path="/account-blocked" component={() => <AccountBlocked />} />

                <Route path="/bad-ssn" component={() => <BadSSNAddress />} />
                <Route path="/processing" component={() => <Processing />} />       
                <Route path="/terms-conditions" component={() => <TermsConditions />} />
                <Route path="/privacy-policy" component={() => <PrivacyPolicy />} />

              </Switch>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
