import React from "react";
import { withRouter } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import { AccountTypeIndex } from "../utils/CodeMapping";
import { insert } from "../utils/helpers";
class InquiryAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentAccordionInquirySelected: -1
    };
  }
  CloseInquiryAddress = i => {
    this.props.addressClose();
    this.setState({ CurrentAccordionInquirySelected: i });
    this.props.ChangeAccordion(i);
  };
  render() {
    const { Inquiry } = this.props;
    const { CurrentAccordionInquirySelected } = this.state;
    return Inquiry.map((inquiry, i) => {
      return (
        <div key={i}>
          <Card
            className={(Inquiry.length-1) === i ? "LastItem" : null}
          >
            <Card.Header
              className={
                CurrentAccordionInquirySelected === i
                  ? " selectedAccordion"
                  : ""
              }
              onClick={() => this.CloseInquiryAddress(i)}
            >
              <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                <div className="row AccordionHeader">
                  <div className="col-4 text-left">
                    {inquiry.SubscriberName}
                  </div>
                  <div className="col-4 text-center">
                    {AccountTypeIndex[inquiry.Type]}
                  </div>
                  <div className="col-4 text-right">
                    {insert(insert(inquiry.Date, "-", 2), "-", 5)}
                  </div>
                </div>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i}>
              <Card.Body
                className={
                  CurrentAccordionInquirySelected === i
                    ? "AccordionSelected"
                    : ""
                }
              >
                <span className="innerTitle">Inquiry Details</span>
                <hr></hr>
                <div className="tradeLine">
                  <div>
                    <div className="row">
                      <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                        Inquirer
                      </div>
                      <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                        {inquiry.SubscriberName}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                        Inquiry Date
                      </div>
                      <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                        {insert(insert(inquiry.Date, "-", 2), "-", 5)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-2">
                        Inquiry Type
                      </div>
                      <div className="col-7 col-sm-7 col-md-9 col-lg-10 col-xl-10 innerValue text-right">
                        {AccountTypeIndex[inquiry.Type]}
                      </div>
                    </div>
                  </div>
                  <div className="row">&nbsp;</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      );
    });
  }
}

export default withRouter(InquiryAccordion);
