import React, { Component } from "react";
import CMNavbar from "./CMNavbar";
import SideMenu from "./SideMenu";
import { Accordion, Card, Button } from 'react-bootstrap';
import { getGlossaryData } from '../api/index';
import ReactHtmlParser from 'react-html-parser';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Auth } from "aws-amplify";
import disableScroll from 'disable-scroll';

class Glossary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			FaqSelected: -1,
			FaqData: [],
			FilteredArray : [],
			loading: true,
			ActiveGlossary : 'All',
			NavStatus  : false,
		}
		Auth.currentAuthenticatedUser({bypassCache: true}).then(data => {
			let data_attributes = data.attributes;
			let subscription_active = data_attributes['custom:subscription_active'];
			if(subscription_active){
				getGlossaryData().then(response => {
					this.setState({
						FaqData: response,
						FilteredArray:response,
						loading: false
					});
				});
			}
		});		
		disableScroll.off();
		window.scrollTo(0, 0);		
	}
	FaqClick = (i) => {
		
		this.setState({
			FaqSelected: i
		});
	}
	GlossaryClick = (i) => {
		let FilteredArray = [];
		if(i == 'All'){
			FilteredArray = this.state.FaqData;
		}
		if(i == 'A-Z'){
			FilteredArray = this.state.FaqData.filter(function (item) {			
				return item.Question.charAt(0).toUpperCase() != item.Question.charAt(0).toLowerCase()               
			});		
		}
		if(i.length == 1){
			FilteredArray = this.state.FaqData.filter(function (item) {			
				return item.Question.charAt(0) == i                  
			});
		}
	
	
		this.setState({
			ActiveGlossary : i,
			FilteredArray : FilteredArray
		})		
	}
	onSideMenuChange = (NavStatus) => {
		if(NavStatus){
		  disableScroll.on();
		}else{
		  disableScroll.off();
		}
		this.setState({
		  NavStatus : NavStatus
		})  
	}
	render() {
		const { NavStatus,FaqSelected, FilteredArray, loading,ActiveGlossary } = this.state;
		return (
			<div className="Profile MainPage FaqPage" >
				<CMNavbar onSideMenuChange={this.onSideMenuChange} anonymous={false} />
				<div className="navbarSpace"></div>
				<div className="row">
					<div className="col-12">
						<div className={"leftSidebar "+ (NavStatus ? "OpenMobile" : "CloseMobile d-none d-sm-block")}>
							<SideMenu />
						</div>
						<div className="Content">
							
							<div className="row FaqTitle profile-top-bar-no-background">
								Glossary
							</div>	
							<div className="row FaqSubtitle profile-top-bar-no-background">
								Creditworthiness and ultimately the scores a lender sees can be impacted by many different financial aspects relating to a person’s life, often times the relationship is unknown to the consumer. Wouldn’t it be nice to have a glossary that covered all those related terms in one place.
							</div>			
							<div className="card shadow mb-4 table-content Faq">							
								<div className="GlossaryClicks">
									<span className={`GlossaryIcon ${ActiveGlossary == 'All' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("All")}>All</span>								
									<span className={`GlossaryIcon ${ActiveGlossary == 'A' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("A")}>A</span>
									<span className={`GlossaryIcon ${ActiveGlossary == "B" ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("B")}>B</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'C' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("C")}>C</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'D' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("D")}>D</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'E' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("E")}>E</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'F' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("F")}>F</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'G' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("G")}>G</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'H' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("H")}>H</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'I' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("I")}>I</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'J' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("J")}>J</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'K' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("K")}>K</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'L' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("L")}>L</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'M' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("M")}>M</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'N' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("N")}>N</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'O' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("O")}>O</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'P' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("P")}>P</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'Q' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("Q")}>Q</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'R' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("R")}>R</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'S' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("S")}>S</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'T' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("T")}>T</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'U' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("U")}>U</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'V' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("V")}>V</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'W' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("W")}>W</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'X' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("X")}>X</span>
									<span className={`GlossaryIcon ${ActiveGlossary == 'Z' ? 'IsActive' : ''}`} onClick={() => this.GlossaryClick("Z")}>Z</span>
									
								</div>
							</div>
			
							<div className="card shadow mb-4 table-content Faq">
								<div className="loading_sign">
									<ScaleLoader
										sizeUnit={"px"}
										size={150}
										color={'#58b0d3'}
										loading={loading}
									/>
								</div>
								{loading == false ? <Accordion>
									{
										FilteredArray.map((item, key) =>
											<Card key={key} className={FaqSelected === key ? "AccordionSelected" : ""} onClick={() => this.FaqClick(key)}>
												<Card.Header>
													<Accordion.Toggle as={Button} variant="link" eventKey={key}>
														{ReactHtmlParser(item.Question)}
													</Accordion.Toggle>
												</Card.Header>
												<Accordion.Collapse eventKey={key}>
													<Card.Body>
														{ReactHtmlParser(item.Answer)}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										)
									}
								</Accordion> : null}
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}
export default Glossary;
