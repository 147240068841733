import React, { Component } from "react";
import { Redirect,withRouter } from "react-router-dom";

class Homesignup extends Component {
  constructor(props) {
		super(props);
		this.state = {
			SignUpEmail : '',
		}
	}
	SignUp = () => {    
		this.props.history.push({
			pathname:"/signup",
			state:{ SignUpEmail:this.state.SignUpEmail }
		});
	}
  render() {
    const {SignUpEmail} = this.state;
    return (
      <section id="signup">
        <div className="container">
          <div className="row bg-img">
            <div className="col-lg-6">
              <h2>
                Sign Now For <span>Recent Updates</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <fieldset>
                <input 
                    type="email" value={SignUpEmail} 
                    className="form-control" placeholder="Enter your email here"
                    onChange={e => this.setState({ SignUpEmail: e.target.value })}
                ></input>
                <button className="SignUp" onClick={this.SignUp}>Sign Up</button>
              </fieldset>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(Homesignup);
