import React from "react";

import { withRouter } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

class LoadingScreen extends React.Component {
  render() {
    
    return (
      <div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <ScaleLoader
                sizeUnit={"px"}
                size={200}
                color={"#58b0d3"}                
              />
            </div>
          </div>
      </div>
    )
  }
}

export default withRouter(LoadingScreen);
