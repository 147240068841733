import React, { Component } from "react";
import { Redirect,withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";

class TermsConditions extends Component {
  constructor(props) {
		super(props);
		this.state = {
	
        }
        window.scrollTo(0, 0)
    }
    componentDidMount() {
        
    }
    renderTC(){
        return(
            <div className="container">
                <div className="TCPage">
                    <div className="header_1">Welcome to CreditMaestro!</div>
                    <br></br>
                    <div className="normal_text">Upon becoming a member of CreditMaestro, you enter into a legal agreement, which is outlined below. As a member you agree to the terms as they are outlined and are aware that they may be updated from time to time, with the latest applicable version laid out as follows.</div>
                    <br></br>
                    <div className="header_2">Terms of Service</div>
                    <br></br>
                    <div className="normal_text">By accessing and using our Services (defined below), you are agreeing to these Terms of Service, our <u>Privacy Policy</u>, any applicable Additional Terms (defined below), all collectively referred to as the &ldquo;Agreement&rdquo;. It is important to read our entire Agreement carefully as some of the provisions affect your legal rights.</div>
                    <br></br>
                    <ol>
                    <li> Our Services</li>
                    </ol>
                    <div className="normal_text">When we refer to our &ldquo;Services,&rdquo; we mean all products and services owned and operated by CreditMaestro.com, including the content, features, tools, data, software and functions made available by CreditMaestro through <a href="http://www.creditmaestro.com">www.creditmaestro.com</a>, our mobile applications, and other websites or applications we operate, unless those websites or applications have posted separate or additional terms of service. As of the effective date of these terms, our Services include the following key features:</div>
                    <br></br>
                    <ul>
                    <li><strong>Credit Score and Report Monitoring</strong>. We provide you with credit reports and access to multiple credit scores through a major credit bureau, along with credit monitoring, among other features that maybe included at a later date.</li>
                    <li><strong>Score Insights</strong>. We provide the adverse factors that are negatively affecting your credit scores and how that relates to the overall makeup of a particular scoring model.</li>
                    <li><strong>Related Credit Information</strong>. We provide information on common credit score and credit report misconceptions as well as an expansive credit glossary aimed at defining all matters that may affect your credit score or report.</li>
                    </ul>
                    <div className="normal_text">We provide you with a membership-based platform that aims to assist you, a consumer, to better understand your creditworthiness and how that is ultimately impacted.</div><br></br>
                    <div className="normal_text">We reserve the right in our sole and absolute discretion to make changes to how we operate and provide our Services, including adding new services, modifying existing services, or suspending, discontinuing, or terminating your access to any or all portions of our Services. Some of our Services are subject to additional terms and conditions ("Additional Terms"), which are posted separately from these terms but are incorporated and form a part of the Agreement if you decide to use or access those features. If there is a conflict between applicable Additional Terms and these terms, the Additional Terms will control.</div>
                    <br></br>
                    <ol start="2">
                    <li><strong> Your CreditMaestro Account</strong></li>
                    </ol>
                    <div className="header_3">2.1 General Requirements</div>
                    <div className="normal_text">
                    <br></br>
                     You will need to register for a CreditMaestro member account to access our Services, like your credit scores, credit reports, full FAQ library and glossary. Registering for a member account is optional, but if you do not register for an account, you will not be able to access our Services.
                     <br></br>
                     <br></br>
                     By registering for a CreditMaestro member account, you certify that:
                     <br></br>
                     <br></br>
                    <ul>
                    <li>You are 18 years of age or older</li>
                    <li>You have a valid U.S. Social Security number</li>
                    <li>Any information you provide to us, both when you register and in the future, is and will be true, accurate, current and complete</li>
                    <li>You are only registering an account for yourself</li>
                    <li>You will keep all information up-to-date</li>
                    </ul>
                    You must not sell, transfer, or assign your account to anyone else. You must keep your password confidential, you must not share it and you may not allow anyone else to log into our Services as you. You are responsible for all activities that occur under your member account. If you believe that your account is no longer secure, notify us immediately by email at abuse@creditmaestro.com.
                    </div>
                    <br></br>
                    <div className="header_3">2.2 Specific Registration Consents</div>
                    <br></br>
                    <div className="normal_text">When you register as a member, you authorize us to create and maintain an account in your name using your account registration information and other information that may be collected about you in accordance with our Privacy Policy as part of providing our Services (collectively, such information is your &ldquo;Member Profile.&rdquo;). By registering, you also specifically consent to let us request and receive your consumer report data and other data about you from third parties to include in your Member Profile as follows:</div>
                    <div className="normal_text">I understand that I am providing written instructions in accordance with the Fair Credit Reporting Act and other applicable law for CreditMaestro or its affiliated companies to request and receive copies of consumer reports, scores and other information about me from third parties, including, but not limited to, Experian. I understand that my instructions let CreditMaestro and affiliated companies obtain such information at any time for as long as I have a registered CreditMaestro account to use as described in the Terms of Service and Privacy Policy. When you register for an account, you're asking us to create your member profile and access your credit scores and reports, and other information so we can provide our services.</div>
                    <br></br>
                    <ol start="3">
                        <li><strong> Limitations and Restrictions on Use of Our Services</strong></li>
                    </ol>
                    <div className="header_3">3.1 Analysis and Estimates</div>
                    <br></br>
                    <div className="normal_text">As described in our <u>Privacy Policy</u>, we may use your credit report, credit score, and other information we obtain to analyze, sort, and present certain information or features to you.</div>
                    <br></br>
                    <div className="normal_text">Any analysis and estimates we provide as part of our Services are for illustrative and informational purposes only. Our analysis are based on certain assumptions and use only the data we have. Our analysis and estimates are neither endorsed by, nor commissioned by, any of our partners. The methodology we use to conduct our analysis is proprietary and we may elect to consider, ignore, emphasize, or de-emphasize certain factors in our sole and absolute discretion.</div>
                    <br></br>
                    <div className="header_3">3.2 Educational Purposes</div>
                    <br></br>
                    <div className="normal_text">All information on our Services is presented for educational purposes only. We do not guarantee that the information we present as part of our Services, including credit report or credit score information, is the same information that may be used or reviewed by a third party to assess your creditworthiness or eligibility for any particular product or service or for employment.</div>
                    <br></br>
                    <div className="normal_text">The credit scores that lenders and others use may be different than the one you see here. CreditMaestro aims to provide credit scores that are commonly utilized in specific lending industries. Some lenders still may use other models that we do not present, and even models that have been developed by the lender themselves.</div>
                    <br></br>
                    <div className="header_3">3.3 Not a Legal or Professional Advisor</div>
                    <br></br>
                    <div className="normal_text">CreditMaestro is not a credit repair organization, credit counselor, or financial or legal advisor as defined under federal or state law. Use of our Services is not a replacement for personal, professional advice or assistance regarding your finances, credit history.</div>
                    <br></br>
                    <div className="normal_text">We aim to be as helpful as we can, but we're not your professional financial or legal advisor. If you need professional assistance with your finances, please go to a professional that can help with your specific situation.</div>
                    <br></br>
                    <div className="header_3">3.4 Territorial Restrictions</div>
                    <br></br>
                    <div className="normal_text">We control and operate our Services from the United States and we make no representations or warranties that the information, products, or services provided through our Services are appropriate for access or use in other jurisdictions. We reserve the right to limit the availability of our Services or the provision of any of our Content to any person, geographic area, or jurisdiction, at any time and in our sole and absolute discretion.</div>
                    <br></br>
                    <div className="header_3">3.5 Compliance with Law</div>
                    <br></br>
                    <div className="normal_text">You must comply with all applicable laws when using our Services. You are not permitted to use our Services to obtain any consumer credit report, score or other consumer information under false pretenses or in violation of the Fair Credit Reporting Act or applicable state or federal law.</div>
                    <br></br>
                    <div className="header_3">3.6 Links to or Connections with Third Party Sites or Applications</div>
                    <br></br>
                    <div className="normal_text">Our Services or communications to you may contain third party content or links to third party sites, applications or services (collectively, &ldquo;Third Party Content&rdquo;). Our Services may also include features that allow you to connect your CreditMaestro account with accounts or services provided by third parties, such as accounts you maintain with financial institutions or social media platforms like Facebook (collectively, &ldquo;Third Party Services&rdquo;). We do not control, maintain, or endorse the Third Party Content or Third Party Services, and we are not responsible or liable for any Third Party Content or Third Party Services, including any damages, losses, failures, or problems caused by, related to, or arising from Third Party Content or Third Party Services. Your interactions and business dealings with the providers of the Third Party Content or Third Party Services, including products or services offered by such third parties, are solely between you and the third party. You should review all of the relevant terms and conditions associated with Third Party Content or Third Party Services, including any privacy policies and terms of service. We are not responsible for any information that you agree to share with third parties in connection with Third Party Content or Third Party Services.</div>
                    <br></br>
                    <ol start="4">
                        <li><strong> Ownership and Infringement</strong></li>
                    </ol>
                    <div className="header_3">4.1 Our Content</div>
                    <br></br>
                    <div className="normal_text">All content and materials, including but not limited to images, text, visual interfaces, information, data, and computer code, provided by us through our Services (our &ldquo;Content&rdquo;) and all related intellectual property rights are the property of CreditMaestro and/or its third-party licensors. Our Content is protected by U.S. and international intellectual property laws and treaties. You are permitted to use our Content only on our Services and you may not copy, modify, sell, reproduce, distribute, republish, display, post, create derivative or collective works from, or transmit in any form (including in-line linking or mirroring) our Content, in whole or in part, without our express prior written consent. You may not reverse engineer or reverse compile any of our Services or the technology used to operate our Services. Nothing in the Agreement or our Services grants you, by implication, estoppel, or otherwise, any license or right to use our Content except as expressly stated in the Agreement.</div>
                    <br></br>
                    <ol start="5">
                    <li><strong> Monitoring of Our Services and Disclosure for Administrative and Legal Reasons</strong></li>
                    </ol>
                    <div className="normal_text">CreditMaestro has the right, but not the obligation, to monitor our Services electronically. We may access, use, preserve, transfer or disclose, at any time and without notice to you, any information (including Personal Information as defined in our Privacy Policy) as reasonably necessary to: (1) comply with any applicable law, regulation, subpoena, or legal process, or respond to any governmental request or cooperate with law enforcement, if we believe such action is required or permitted by law; (2) enforce this Agreement, including to investigate potential violations; (3) protect the safety, integrity, rights, or security of our members, our Services or equipment, or any third party; or (4) detect, prevent, or otherwise address fraud, security, or technical issues related to our Services or the services of our partners.</div>
                    <br></br>
                    <ol start="6">
                    <li><strong> Disclaimers </strong></li>
                    </ol>
                    <div className="normal_text_bold">WE PROVIDE OUR SERVICES, OUR CONTENT, AND ALL CONTENT AND MATERIALS AVAILABLE THROUGH OUR SERVICES &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; WITHOUT ANY WARRANTY OR CONDITION OF ANY KIND, EXPRESS OR IMPLIED. CREDITMAESTRO, ON BEHALF OF ITSELF, ITS AFFILIATES AND ITS LICENSORS, DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO OUR SERVICES, OUR CONTENT, OR THE COMMUNITY CONTENT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, QUALITY, ACCURACY, TITLE AND NON-INFRINGEMENT, AND ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE.</div>
                    <br></br>
                    <div className="normal_text_bold">NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OUR SERVICES, OUR CONTENT, THE COMMUNITY CONTENT, OR OTHERWISE AVAILABLE THROUGH OUR SERVICES WILL CREATE ANY WARRANTY REGARDING CREDITMAESTRO OR ANY OF OUR SERVICES THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU USE OUR SERVICES, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN OUR CONTENT, THE COMMUNITY CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR SERVICES, AT YOUR OWN DISCRETION AND RISK. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO OUR SERVICES, YOUR DEALINGS WITH ANY OTHER MEMBER OR THIRD PARTY, AND YOUR USE OF OUR CONTENT, THE COMMUNITY CONTENT, OR OTHER CONTENT AVAILABLE THROUGH OUR SERVICES. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING ANY COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH OUR SERVICES), OR THE LOSS OF DATA THAT MAY RESULT FROM THE USE OF OUR SERVICES OR THE USE OF ANY OF OUR CONTENT, THE COMMUNITY CONTENT OR OTHER CONTENT AVAILABLE THROUGH OUR SERVICES. </div>
                    <br></br>
                    <div className="normal_text_bold">YOU ACKNOWLEDGE THAT CREDITMAESTRO IS SOLELY AN INTERMEDIARY (I) BETWEEN YOU AND ANY CREDIT REPORTING AGENCY OR OTHER INFORMATION PROVIDERS ACTUALLY SUPPLYING YOUR CREDIT REPORTS, CREDIT SCORES AND OTHER INFORMATION. CREDITMAESTRO EXPRESSLY DISCLAIMS ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN OUR CONTENT OR OUR SERVICES, INCLUDING THE INACCURACY OR INCOMPLETENESS OF ANY SUCH CREDIT REPORTS, CREDIT SCORES, OFFERS OR OTHER INFORMATION, TOOLS OR ANALYSES AVAILABLE THROUGH OUR SERVICES OR OUR CONTENT.</div>
                    <br></br>
                    <div className="normal_text_bold">NOTWITHSTANDING THE FOREGOING, NOTHING IN THIS SECTION SHALL AFFECT WARRANTIES WHICH ARE INCAPABLE OF EXCLUSION OR RESTRICTION UNDER APPLICABLE LAW.</div>
                    <br></br>
                    <ol start="7">
                    <li><strong> Limitation of Liability</strong></li>
                    </ol>
                    <div className="normal_text_bold">CREDITMAESTRO AND ITS REPRESENTATIVES, AGENTS, AND ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EQUITY HOLDERS, EMPLOYEES, PARENT ENTITIES, SUBSIDIARIES, AFFILIATED ENTITIES, REPRESENTATIVES, AGENTS AND LICENSORS (COLLECTIVELY, THE &ldquo;CREDITMAESTRO PARTIES&rdquo;) WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (SUCH AS LOSS OF PROFITS, LOSS OF GOODWILL, LOSS OF USE, LOSS OF DATA, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES) ARISING OUT OF OR RELATING IN ANY WAY TO OUR SERVICES, OUR CONTENT OR THE COMMUNITY CONTENT.</div>
                    <br></br>
                    <div className="normal_text_bold">EXCEPT AS EXPRESSLY PROVIDED IN SECTION 9 (DISPUTE RESOLUTION AND ARBITRATION) OR REQUIRED BY APPLICABLE LAW (E.G., ANY NON-WAIVABLE RIGHTS OR REMEDIES), IN NO EVENT WILL THE TOTAL LIABILITY OF THE CREDITMAESTRO PARTIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF, OR ANY INABILITY TO USE ANY PORTION OF, OUR SERVICES, OR OTHERWISE ARISING OUT OF OR RELATING TO THE AGREEMENT, WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED IN THE AGGREGATE ONE HUNDRED UNITED STATES DOLLARS ($100.00).</div>
                    <br></br>
                    <div className="normal_text_bold">IF ANY OF THE FOREGOING LIMITATIONS ARE FOUND TO BE INVALID, THE CREDITMAESTRO PARTIES' TOTAL LIABILITY FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION OF ANY KIND OR NATURE WHATSOEVER SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW.</div>
                    <br></br>
                    <ol start="8">
                    <li><strong> Indemnification</strong></li>
                    </ol>
                    <div className="normal_text">You will defend (if requested by any CreditMaestro Party), indemnify, and hold harmless the CreditMaesrto Parties from and against any and all claims, losses, expenses, demands, or liabilities, including attorneys' fees and costs, incurred by the CreditMaestro Parties in connection with any claim by a third party arising out of or in any way related to: (1) your use of our Services, our Content or the Community Content; (2) your violation or alleged violation of the Agreement or your violation or alleged violation of any applicable law; (3) your infringement or alleged infringement of any intellectual property or other right of any other person or entity; or (4) any dispute between you and a third party, including any provider of Third Party Content. You must not settle any such claim or matter without the prior written consent of CreditMaestro. The CreditMaestro Parties reserve the right, at their own expense, to assume the exclusive defense and control of any matter subject to indemnification by you, and you further agree that you will cooperate fully in the defense of any such claims.</div>
                    <br></br>
                    <ol start="9">
                    <li><strong> Dispute Resolution and Arbitration</strong></li>
                    </ol>
                    <div className="header_3">9.1 Generally</div>
                    <br></br>
                    <div className="normal_text">In the interest of resolving disputes between you and CreditMaestro in the most expedient and cost-effective manner, you and CreditMaestro agree that every dispute arising in connection with the Agreement will be resolved by binding arbitration. You and us further agree that the Federal Arbitration Act (&ldquo;FAA&rdquo;) governs the interpretation and enforcement of this arbitration provision. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of the Agreement, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of the Agreement. The arbitrator also has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.</div>
                    <div className="normal_text">YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THE AGREEMENT, YOU AND CREDITMAESTRO ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</div>
                    <br></br>
                    <div className="header_3">9.2 Exceptions </div>
                    <br></br>
                    <div className="normal_text">Despite the provisions of Section 9.1, you and us both agree that nothing in the Agreement will be deemed to waive, preclude or otherwise limit the right of either of us to: (a) bring an individual action in small claims court within its jurisdictional limits; (b) file a complaint with the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file a suit in a court of law solely for injunctive relief to stop unauthorized use or abuse of our Services or address an intellectual property infringement claim.</div>
                    <br></br>
                    <div className="header_3">9.3 Arbitrator</div>
                    <br></br>
                    <div className="normal_text">Any arbitration between you and CreditMaestro will be governed by the FAA, the Commercial Dispute Resolution Procedures and the Supplementary Procedures for Consumer Related Disputes (collectively, "<strong>AAA Rules</strong>") of the American Arbitration Association ("<strong>AAA</strong>"), as modified by the Agreement, and will be administered by the AAA. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting CreditMaestro, info@creditmaestro.com.</div>
                    <br></br>
                    <div className="header_3">9.4 Notice and Process</div>
                    <br></br>
                    <div className="normal_text">A party who intends to seek arbitration must first send a written notice of the dispute to the other by certified mail ("<strong>Notice</strong>"). CreditMaestro&rsquo;s address for Notice is: CreditMaestro, P.O. Box 520, San Francisco, CA 94104-0520, Attention: Legal Department. The Notice must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought ("<strong>Demand</strong>"). You and CreditMaestro agree to use good faith efforts to resolve the claim directly, but if you and CreditMaestro do not reach an agreement to do so within 30 days after the Notice is received, you or us may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or CreditMaestro must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If, after finding in your favor in any respect on the merits of your claim, the arbitrator issues you an award that is greater than the value of CreditMaestro last written settlement offer made before an arbitrator was selected, CreditMaestro will pay you the amount awarded by the arbitrator or fifteen thousand United States dollars ($5,000), whichever is greater. The arbitration proceedings, including any exchanged materials, shall be confidential and you and CreditMaestro agree to use reasonable efforts to streamline the proceedings, including discovery, consistent with the objectives of efficient dispute resolution.</div>
                    <br></br>
                    <div className="header_3">9.5 Fees</div>
                    <br></br>
                    <div className="normal_text">If you commence arbitration in accordance with the Agreement, CreditMaestro will reimburse you for your payment of the filing fee, unless your claim is for more than $5,000, in which case the payment of any fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon in Seattle, Washington, but if the claim is for $5,000 or less, or as otherwise required, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your registered account address. If the arbitrator finds that either the substance of the claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse CreditMaestro for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within fourteen (14) days of the arbitrator's ruling on the merits.</div>
                    <br></br>
                    <div className="header_3">9.6 No Class Actions </div>
                    <br></br>
                    <div className="normal_text">YOU AND CREDITMAESTRO AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR A CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. </div>
                    <div className="normal_text">Further, unless both you and CreditMaestro agree otherwise, the arbitrator may not consolidate more than one person's claims or otherwise preside over any form of a representative or class proceeding.</div>
                    <br></br>
                    <div className="header_3">9.7 Modifications</div>
                    <br></br>
                    <div className="normal_text">If CreditMaestro makes any future change to this arbitration provision (other than a change to CreditMaestro&rsquo;s address for Notice), you may reject the change by sending us written notice within 30 days of the change to CreditMaestro&rsquo;s address for Notice, in which case your account with CreditMaestro will be immediately terminated but this arbitration provision, as in effect immediately prior to the amendments you reject, will survive.</div>
                    <br></br>
                    <div className="header_3">9.8 Enforceability</div>
                    <br></br>
                    <div className="normal_text">If any provision of this Section 9 is unlawful, void, or unenforceable for any reason, then that provision will be given effect to the greatest extent possible and the remaining provisions will remain in full force and effect.</div>
                    <br></br>
                    <ol start="10">
                    <li><strong> Deactivation and Termination</strong></li>
                    </ol>
                    <div className="header_3">10.1 Termination of Agreement; Surviving Terms.</div>
                    <br></br>
                    <div className="normal_text">The Agreement is effective until your member account, if applicable, is deactivated by either you or CreditMaestro and you discontinue all use of our Services. Notwithstanding the foregoing, any section of the Agreement that by its nature is intended to survive termination of the Agreement or your use or access to our Services or our Content will survive such termination.</div>
                    <br></br>
                    <ol start="11">
                    <li><strong> Miscellaneous</strong></li>
                    </ol>

                    <div className="header_3">11.1 Governing Law</div>
                    <br></br>
                    <div className="normal_text">The Agreement is governed by the laws of the State of Washington, excluding conflicts of law provisions.</div>
                    <br></br>
                    <div className="header_3">11.2 Entire Agreement</div>
                    <br></br>
                    <div className="normal_text">The terms of the Agreement (including the Privacy Policy, the Community Rules and any Additional Terms) constitute the entire and exclusive agreement between CreditMaestro and you in connection with your use of our Services or our Content.</div>
                    <br></br>
                    <div className="header_3">11.3 Changes to the Agreement</div>
                    <br></br>
                    <div className="normal_text">We may modify the Agreement at any time. We will post modifications to these Terms of Service on this page. If a change materially modifies your rights or obligations, you may need to accept the modified terms to continue to use our Services. Material modifications will take effect when you accept the modified terms; immaterial modifications will take effect when published. By continuing to use our Services following any modification to these terms, our Privacy Policy or any Additional Terms, you are agreeing to the Agreement as modified.</div>
                    <br></br>
                    <div className="header_3">11.4 Waiver Only in Writing</div>
                    <br></br>
                    <div className="normal_text">CreditMaestro&rsquo;s failure to enforce any of its rights or act with respect to a breach by you or others of the Agreement does not constitute a waiver of any rights and will not limit CreditMaestro's rights with respect to that breach or any subsequent breaches. No waiver by CreditMaesrto of any of the provisions in the Agreement will be of any force or effect unless made in writing and signed by a duly authorized officer of CreditMaestro.</div>
                    <br></br>
                    <div className="header_3">11.5 Assignment</div>
                    <br></br>
                    <div className="normal_text">CreditMaestro may assign the Agreement to any party at any time without notice to you. You may not assign your rights under the Agreement, by operation of law or otherwise, without our consent.</div>
                    <br></br>
                    <div className="header_3">11.6 Severability</div>
                    <br></br>
                    <div className="normal_text">If any provision of the Agreement is unlawful, void, or unenforceable for any reason, then that provision will be given effect to the greatest extent possible and the remaining provisions will remain in full force and effect. <strong>Nothing in the Agreement shall affect any non-waivable statutory rights or remedies that apply to you.</strong></div>
                    <br></br>
                    <div className="header_3">11.7 Interpretation</div>
                    <br></br>
                    <div className="normal_text">The titles and annotations contained in these terms are inserted only as a matter of convenience and have no legal or contractual effect. The Agreement will be construed in favor of CreditMaestro in the event of any misunderstanding.</div>
                    <br></br>
                    <p>&nbsp;</p>
                </div>
            </div>
            
        )
        
    }
    render() {
        return(
            <div>
                <CMNavbar anonymous={true} />
                {this.renderTC()}
            </div>
        )
    } 
}
export default withRouter(TermsConditions);
