import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import { isValidEmail, minLength, getToken } from "../utils/helpers";
import { Auth } from "aws-amplify";
import AttributesForm from "./AttributesForm";
import CMNavbar from "./CMNavbar";
import Footer from "./Footer";

import ScaleLoader from "react-spinners/ScaleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
const steps = [
  {
    title: "Register"
  },
  {
    title: "Verify"
  },
  {
    title: "Add Information"
  }
];

class SignUp extends Component {
  constructor(props) {    
    super(props);
    const { state } = props.location;
  
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      emailError: "",
      passwordError: "",
      apiError: "",
      confirmPasswordError: "",
      isLoading: false,
      greenButton: false,
      step: 0,
      ExistingData: [],
    };
    
    const that = this;
    Auth.currentAuthenticatedUser({
      bypassCache: true
    }).then(data => {
      if (data.username) {
        if (typeof data.attributes['custom:subscription_active'] !== 'undefined') {
          that.props.history.push("/dashboard");
        } else {
          this.setState({
            step: 2,
            ExistingData: data.attributes
          });
        }
      }
    });    
    if(typeof props.location.state != "undefined"){    
      this.state.email =  props.history.location.state.SignUpEmail;      
    }
  }

  onEmailChange = e => {
    const email = e.target.value;
    let EmailLower = email.toLowerCase();
    this.setState({ 
        email : EmailLower, 
        emailError: isValidEmail(email) ? "" : "Email entered is invalid" 
    });
  };
  checkPassword = (Password,PasswordConfirmation) => {
  
    if(Password == PasswordConfirmation){
      this.setState({ greenButton : true,confirmPasswordError : "" })
    }else{
      this.setState({ greenButton : false,confirmPasswordError : "Password not matching" })
    }
  }
  onPasswordChange = e => {    
    const password = e.target.value;  
    this.checkPassword(e.target.value,this.state.confirmPassword);
    this.setState({
      password,
      passwordError: minLength(password) ? "" : "Password should be atleast of 8 characters"
    });
    
  };

  onConfirmPasswordChange = e => {    
    this.checkPassword(e.target.value ,this.state.password);
    this.setState({ confirmPassword: e.target.value });    
  };
  handleSignUpKeyPress = (e) =>{
    if (e.key === 'Enter') {
      this.onSignUp();
    }
  };
  handleVerifyUpKeyPress = (e) =>{
    if (e.key === 'Enter') {
    this.verifyOtp();
    }
  };
  onSignUp = () => {
    if(!this.state.email){
      this.setState({emailError : "Please enter Email"});
      return;
    }
    if(!this.state.password){
      this.setState({passwordError : "Please enter Password"});
      return;
    }
    if(!this.state.confirmPassword){
      this.setState({confirmPasswordError : "Please enter Password confirmation"});
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPasswordError: "Password should match" });
      return;
    } else {
      this.setState({ confirmPasswordError: "" });
    }
    this.setState({ isLoading: true });
    Auth.signUp({
      username: this.state.email,
      password: this.state.password,
    }).then(resp => {
      this.setState({ isLoading: false,apiError: "" });
      this.setState({ step: 1 });
    }).catch(error => {
      this.setState({ isLoading: false });
      if (error.code === "UsernameExistsException") {
        this.setState({ apiError: "This email id is already registered" });
      }
    });
  };
  verifyOtp = event => {
    this.setState({ isLoading: true });
    Auth.confirmSignUp(this.state.email, this.state.confirmationCode).then(() => {
      Auth.signIn(this.state.email, this.state.password).then(user => {
        this.setState({ step: 2 });
      }).catch(err => console.log(err));
    }).catch(error => {
      this.setState({ apiError: error.message });
      this.setState({ isLoading: false });
    });
  };

  renderSignUpForm() {
    const {isLoading,apiError} = this.state;
    return (
      <div className="formContainer">
        <div className="row title justify-content-center">Sign Up</div>
          <div className="row CreditMaestroAdvantageText">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {apiError && <div className="col-12 text-center error">{this.state.apiError}</div>}
                  <label>Email</label>
                  <input
                    type="text"
                    className="inputField form-control"
                    value={this.state.email}
                    onChange={this.onEmailChange}
                    placeholder="Enter Email"
                    onKeyPress={this.handleSignUpKeyPress}
                  />
                  {this.state.emailError && <div className="col-12 error">{this.state.emailError}</div>}
                  <br></br>
                  <label>Password</label>
                  <input
                    type="password"
                    className="inputField form-control"
                    value={this.state.password}
                    onChange={this.onPasswordChange}
                    placeholder="Enter Password"
                    onKeyPress={this.handleSignUpKeyPress}
                  />
                  {this.state.passwordError && (
                    <div className="col-12 error">{this.state.passwordError}</div>
                  )}
                  <br></br>
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="inputField form-control"
                    value={this.state.confirmPassword}
                    onChange={this.onConfirmPasswordChange}
                    placeholder="Enter the same Password"
                    onKeyPress={this.handleSignUpKeyPress}
                  />
                  {this.state.confirmPasswordError && (
                    <div className="col-12 error">{this.state.confirmPasswordError}</div>
                  )}
                  <br></br>
                  {isLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> : <input disabled={this.state.isLoading} 
                    type="button" className={`btn btn-primary w-100 ${this.state.greenButton ? 'isGreen' : ''}`} 
                    value="Sign Up" onClick={this.onSignUp} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  <p className="text-center">CreditMaestro Advantage</p>              
                  <ul className="CreditMaestroAdvantage">
                    <li><FontAwesomeIcon icon={faCheck} className="faCheck" />&nbsp;Credit score monitoring for the Credit Score that matters</li>
                    <li><FontAwesomeIcon icon={faCheck} className="faCheck" />&nbsp;Credit Report Monitoring</li>
                    <li><FontAwesomeIcon icon={faCheck} className="faCheck" />&nbsp;Public record reporting</li>
                    <li><FontAwesomeIcon icon={faCheck} className="faCheck" />&nbsp;Report change notifications</li>
                    <li><FontAwesomeIcon icon={faCheck} className="faCheck" />&nbsp;Credit monitoring and Credit Score tracking for $9.99/month.</li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
                </div>
              </div>
              <div className="row FooterText">
                <div className="text-center col-12">                  
                  <input
                    type="btn"
                    className="btn btn-link w-100"
                    placeholder="Already have an account?"
                    onClick={() => this.props.history.push("/signin")}
                  />
                  <div className="row small_text">
                    <div className="text-center col-12">
                      All data is encrypted for transmission and storage. Data storage is in compliance with ISO 27001 standards and SOC II data security standards.
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>              
    );
  }
  
    
  
  submitAttributes = () => {
    
  }
  onOTPChange = e => {
    this.setState({
      confirmationCode: e.target.value
    });
  };

  renderConfirmationForm() {
    const {isLoading} = this.state;
    return (
      <div className="formContainer">
        <div className="row title justify-content-center">Confirm</div>
        <div className="row">&nbsp;</div>
        
        <div className="row">&nbsp;</div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="row">
              {this.state.apiError && <div className="col-12 text-center error">{this.state.apiError}</div>}
            </div>
              <label>To continue, please enter the 6-digit verification code sent to your registered email, {this.state.email}</label>
            <input
              type="number"
              className="inputField"
              value={this.state.confirmationCode}
              onChange={this.onOTPChange}
              onKeyPress={this.handleVerifyUpKeyPress}
            />
          </div>
        </div>

        <div className="row">&nbsp;</div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center">
            {isLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> :<input type="button" value={`Continue`} onClick={this.verifyOtp} className="btn btn-primary w-100"></input>  }     
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { step, ExistingData } = this.state;
    let stepToRender;
    switch (step) {
      case 0:
        stepToRender = this.renderSignUpForm();
        break;
      case 1:
        stepToRender = this.renderConfirmationForm();
        break;
      case 2:
        stepToRender = <AttributesForm submitAttributes={this.submitAttributes} ExistingData={ExistingData} />;
        break;
      default:
        break;
    }

    return (
      <div className="signUpProcess">
        <div className="row">
          <CMNavbar anonymous={true} />                
        </div>
        <div className="stepper">
          <Stepper steps={steps} activeStep={step} />
        </div>
        
        {stepToRender}
        <div className="FooterAnonymous">
          <Footer  anonymous="true"></Footer>
        </div>       
      </div>
    );
  }
}

export default withRouter(SignUp);
