import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";
import SideMenu from "./SideMenu";
import { Auth } from 'aws-amplify';
import  {notify} from 'react-notify-toast';
import { profilePicture,postStripeUpdateCardSessionData,postStripeCancel,postStripeResume } from '../api/index';
import Avatar from 'react-avatar-edit'
import config from "../config";
import { loadStripe } from "@stripe/stripe-js";
import ScaleLoader from "react-spinners/ScaleLoader";
import {  Modal } from "react-bootstrap";
import { getSubscriptionData } from "../api/index";
import PaymentIcon from 'react-payment-icons';
import disableScroll from 'disable-scroll';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name : '',
            last_name : '',
            notificationColor : { 
                background: '#3F25EE', 
                text: "#000000" 
            },
            preview: null,
            src : '',
            UpdateCardLoading : false,
            DeleteCardLoading : false,
            SubscriptionDeleteConfirm: false,
            ResumeSubscriptionLoading: false,
            SubscriptionData : [],
            NavStatus  : false,
        }
        this.onCrop = this.onCrop.bind(this);
        this.onClose = this.onClose.bind(this);
        this.getSubscription();
        disableScroll.off();
        window.scrollTo(0, 0);		
    }
    getSubscription = () => {
        getSubscriptionData().then(response => {
            
            this.setState({ SubscriptionData : response});
        });
    }
    async handleSubmit(event) {
        event.preventDefault();
        let user = await Auth.currentAuthenticatedUser();     
    }
    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(data => {                        
            let user_data = data.attributes;                        
            console.log(user_data);
            this.setState({ 
                first_name : user_data['given_name'],
                last_name : user_data['family_name'] 
            });        
        }).catch(err => {});  
    }
    onClose() {
        let data = {
            'picture' : this.state.preview
        }
        document.getElementById("navbarprofileImage").src = this.state.preview;
        profilePicture(data).then(response => {
            this.setState({preview: null})
        });
    }
    onCrop(preview) {
        this.setState({ preview:preview });
    }
    CancelSubscription = () => {
        alert("cancel subscription");
    }
    passToCheckoutRediredct = async SessionID => {
        const stripePromise = loadStripe(config.credit_maestro.STRIPE_ID);
        const stripe = await stripePromise;
        const { error } = stripe.redirectToCheckout({
          sessionId: SessionID
        });
    };
    updateCard = () => {      
        this.setState({
            UpdateCardLoading : true,
        });  
        postStripeUpdateCardSessionData().then(response => {            
            this.passToCheckoutRediredct(response);            
        })
    }
    handleDeleteSubscriptionConfirmation = () => {
        this.setState({
            DeleteCardLoading : true,
        });  
        postStripeCancel().then(response => {            
            this.props.history.push("/processing");
        });
    }
    resumeSubscription = () => {
        this.setState({
            ResumeSubscriptionLoading : true,
        });  
        postStripeResume().then(response => {
            this.props.history.push("/processing");
        })
    }
    deleteSubscription = () => {
        this.setState({
            SubscriptionDeleteConfirm : true
        });
    }
    handleClose = () => {
        this.setState({
            SubscriptionDeleteConfirm : false
        });
    }
    onSideMenuChange = (NavStatus) => {
		if(NavStatus){
		  disableScroll.on();
		}else{
		  disableScroll.off();
		}
		this.setState({
		  NavStatus : NavStatus
		})  
	}
	render() {
        const {NavStatus,SubscriptionData,UpdateCardLoading,DeleteCardLoading,ResumeSubscriptionLoading} = this.state;
        return (
            <div className="Profile MainPage MembershipBilling">
				<CMNavbar onSideMenuChange={this.onSideMenuChange} anonymous={false} />
                <div className="navbarSpace"></div>
                <div className="row">
                    <div className="col-12">
                        <div className={"leftSidebar "+ (NavStatus ? "OpenMobile" : "CloseMobile d-none d-sm-block")}>
							<SideMenu />
						</div>
                        <div className="Content">
                            <Modal show={this.state.SubscriptionDeleteConfirm} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Cancel Subscription </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            You will continue to have access till the subscription period ends, after which all your data will be poof .
                                        </div>
                                    </div>                      
                                    <div class="row">&nbsp;</div>
                                    <div class="row">&nbsp;</div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            <input class="btn btn-primary" onClick={this.handleDeleteSubscriptionConfirmation} value="Continue"></input>                      
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                                            <input type="submit" onClick={this.handleClose} class="btn btn-danger" value="Cancel"></input>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <div className="card">
                                <div className="card-body">                                    
                                    <div class="row">
                                        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                            <b>Membership and Billing</b>
                                            <div className="row">&nbsp;</div>
                                            <Avatar
                                                onCrop={this.onCrop}
                                                onClose={this.onClose}
                                                src={this.state.src}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                            <label htmlFor="first_name">First Name</label>
                                            <br></br>
                                            {this.state.first_name} 
                                            <br></br>
                                            <br></br>
                                            <label htmlFor="last_name">Last Name</label>
                                            <br></br>
                                            {this.state.last_name}
                                            <br></br>
                                            <hr></hr>
                                            {SubscriptionData.CardNumber ? <div class="row">
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <PaymentIcon
                                                    id={SubscriptionData.CardBrand}
                                                    style={{ margin: 10, width: 50 }}
                                                    className="payment-icon"
                                                />
                                                    <b>XXXX-XXXX-XXXX-{SubscriptionData.CardNumber}</b>
                                                    <br></br>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        {SubscriptionData.CancelAtPeriodEnd == "1" ? <b>Cancelling At : </b> : <div></div> }
                                                        {SubscriptionData.CancelAtPeriodEnd == "1" ? SubscriptionData.SubscriptionEndDate : <div></div>}         
                                                        {!ResumeSubscriptionLoading && SubscriptionData.CancelAtPeriodEnd == "1" ? <a className="ClickAble CancelSubscription" onClick={this.resumeSubscription}>Resume Subscription</a> : null} 
                                                        {ResumeSubscriptionLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> : null}

                                                    </div>                                           
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                                                    {UpdateCardLoading || SubscriptionData.CancelAtPeriodEnd == "1" ? null : <a className="ClickAble" onClick={this.updateCard}>Update Payment Info</a>}
                                                    {UpdateCardLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> : null}
                                                    <br></br>                                                    
                                                    {DeleteCardLoading || SubscriptionData.CancelAtPeriodEnd == "1" ? null : <a className="ClickAble CancelSubscription" onClick={this.deleteSubscription}>Cancel Subscription</a>}
                                                    {DeleteCardLoading ? <ScaleLoader sizeUnit={"px"} size={200} color={"#58b0d3"}/> : null}
                                                </div>
                                            </div>  : null }                                                                                                           
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div> 
                        </div>                   
                    </div>                            
                </div>
            </div>        
        );
    }
}
export default withRouter(Profile);