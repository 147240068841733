export default {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_iefRBDHC5",
    APP_CLIENT_ID: "1b56808c94s1mt7si18ic0bc1q",
    IDENTITY_POOL_ID: ""
  },
  credit_maestro: {
    STAGE: "prod",
    AMAZON_URL: "https://api.credit-maestro.com",
    STRIPE_ID: "pk_test_7AtjoFF9m5UYMn0AinkVmsrX00BUzrM9xv"
  },
  pusher: {
    APP_ID: "d1838ff3079b37e0ff2a",
    CLUSTER: "mt1"
  }
};
