
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CMNavbar from "./CMNavbar";
import SideMenu from "./SideMenu";
import { scoreData } from "../api/index";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Accordion } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import TradeLineAccordion from "./TradeLineAccordion";
import InquiryAccordion from "./InquiryAccordion";
import PublicRecordAccordion from "./PublicRecordAccordion";
import Switch from 'react-ios-switch';
import Arrow from "../static/images/arrow.png";
import { SlideDown } from "react-slidedown";
import Moment from "react-moment";
import { insert } from "../utils/helpers";
import LoadingScreen from "./LoadingScreen";
import disableScroll from 'disable-scroll';
import {  Modal } from "react-bootstrap";

class AccountProfile extends Component {
    constructor(props) {
        super(props);
        let ActiveContent = 'accounts';
        let RecordsToShow = 'all';
        let ClosedItem = false;
        if (typeof(this.props.location.state) !== 'undefined' || this.props.location.state != null) {
          ActiveContent = this.props.location.state.TabsActiveContent;          
          RecordsToShow = this.props.location.state.RecordsToShow;          
          if(ActiveContent == 'accounts'){
            if(RecordsToShow == 'open'){
              ClosedItem = false;
            }
            if(RecordsToShow == 'closed'){
              ClosedItem = true;
            }
          }
        }
        this.state = {
            RD: [],
            Accounts: [],
            AccountsFiltered: [],
            Inquiry: [],
            PublicRecord: [],
            loading: true,
            noAccountsToShow : false,
            ShowPrivateItem : ClosedItem,
            AddressVisible: false,
            pageLoading: true,
            TabsActiveContent : ActiveContent,
            NavStatus: false,
            ResetOpenAccordion : false,
            TradeLineSelected : -1, 
            FileDispute : false
        }
        this.ProcessScoreData();
        disableScroll.off();
        window.scrollTo(0, 0);		        
    }
    ProcessScoreData = () => {
        let max_retry = 0;   
        let process_score_processing = 0;    
        let timerId = setInterval(() =>{      
          if(process_score_processing == 0){
            process_score_processing = 1;
            scoreData().then(response => {
              console.log(response);
              process_score_processing = 0;
              const RD = response;
              clearInterval(timerId);
              if (RD) {
                if (RD.TradeLine) {
                  let FilteredArray = [];
                  if(this.state.ShowPrivateItem){
                    FilteredArray = RD.TradeLine.filter(function (item) {
                      return item.OpenOrClosed == 'C'                  
                    });
                  }else{
                    FilteredArray = RD.TradeLine.filter(function (item) {
                      return item.OpenOrClosed == 'O'                  
                    });
                  }
                  
                  this.setState({ AccountsFiltered: FilteredArray,Accounts:RD.TradeLine });
                }
                if (RD.Inquiry) {
                  this.setState({ Inquiry: RD.Inquiry });
                }
                if (RD.PublicRecord) {
                  this.setState({ PublicRecord: RD.PublicRecord });
                }
                if (RD.AddressList) {
                  this.setState({ AddressList: RD.AddressList });
                }
                if (RD.CurrentAddress) {
                  const SDCurrentAddress = RD.CurrentAddress;
                  this.setState({ CurrentAddress: SDCurrentAddress });
                }
                this.setState({ loading: false, RD: RD,pageLoading: false });
              }
            });
            max_retry++;
          }
          if(max_retry > 5){
            clearInterval(timerId);
          }
        },1000);
    };
    addressClose = () => {
        this.setState({ AddressVisible: false });
    };
    addressOpen = () => {
      this.setState({ AddressVisible: !this.state.AddressVisible });
    };
  
    PrivateItemChange = (e) => {
      this.setState({ShowPrivateItem : e,loading : true,AccountsFiltered:[],TradeLineSelected:-1});      
      if(e){                
        let filteredArray = this.state.Accounts.filter(function (item) {
          return item.OpenOrClosed == 'C'                  
        });
        this.setState({ AccountsFiltered: filteredArray,loading : false,ResetOpenAccordion : true });
      }else{
        let filteredArray = this.state.Accounts.filter(function (item) {
          return item.OpenOrClosed == 'O'                  
        });
        this.setState({ AccountsFiltered: filteredArray,loading : false,ResetOpenAccordion : true });
      }             
    }
    RenderAddress = (AddressList) => {
      return (
        <div className="addressList container-fluid">
          <div className="text-center">
            <div className="addressHeader">Other Reported Addresses</div>
          </div>
          {AddressList.map((item, key) => (
            <div className="" key={key}>
              <div className="Spacing"></div>
              <div className="row addressRow addressTitle">Previous Address</div>
              <div className="row addressRow horizontalRow"></div>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  Address
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                  {
                    this.text_trim(item.STREETPREFIX +
                    " " +
                    item.STREETNAME +
                    " " +
                    item.STREETSUFFIX)+", " +
                    item.CITY +
                    ", " +
                    item.STATE +
                    " " +
                    item.ZIPCODE.substr(0, 5)}
                </div>
              </div>              
              <div className="row addressRow addressData">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  Date Reported
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                  <Moment parse="MM-DD-YYYY" format="MMM D, YYYY">
                    {insert(insert(item.FIRSTREPORTEDDATE, "-", 2), "-", 5)}
                  </Moment>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    text_trim = (a) => {    
      return a.trim();
    }  
    onSideMenuChange = (NavStatus) => {
      if(NavStatus){
        disableScroll.on();
      }else{
        disableScroll.off();
      }
      this.setState({
        NavStatus : NavStatus
      })  
    }
    ChangeAccordion = (i) => {
      this.setState({
        TradeLineSelected : i
      });
    }
    handleFileDisputeClose = () => {
      this.setState({
        FileDispute : false
      });
    }
    FileDispute = () => {
      this.setState({ FileDispute : true });
    }
    render(){
        const {
            TabsActiveContent,            
            CurrentAddress,
            AccountsFiltered,            
            CurrentAccordionAccountsSelected,            
            Inquiry,
            PublicRecord,
            RD,
            ShowPrivateItem,
            AddressList,
            AddressVisible,
            pageLoading,
            NavStatus,
            ResetOpenAccordion,
            TradeLineSelected,
            FileDispute
          } = this.state;    
        return (
            <div className="AccountProfile MainPage">
                <CMNavbar onSideMenuChange={this.onSideMenuChange} anonymous={false} {...this.state}/>   
                <div className="navbarSpace"></div>                
                <div className="row">
                    <div className="col-12">
                          <div className={"leftSidebar "+ (NavStatus ? "OpenMobile" : "CloseMobile d-none d-sm-block")}>
                            <SideMenu  {...this.state}/>   
                          </div>
                          {pageLoading == true ? <div><LoadingScreen/></div> : null}
                          {pageLoading == false ? 
                          
                          <div className="Content">
                            <div className="row profile-top-bar-no-background">
                                <div className="Spacing"></div>
                                <div className="col-8 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                  <span className="ProfileTitle">Credit Report</span>
                                </div>
                                <div className="col-4 col-sm-0 col-md-4 col-lg-4 col-xl-4 text-right">
                                  <div className="FileDispute" onClick={this.FileDispute}>File a dispute?</div>
                                  <Modal show={this.state.FileDispute} onHide={this.handleFileDisputeClose}>
                                    <Modal.Header closeButton>
                                      <Modal.Title>Filing a Dispute</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="FileDispute">
                                        <span>Each credit bureau maintains their own data of consumer records; therefore, if you want to dispute an item on your credit report it would be important to contact each bureau separately. Below are the particular contact addresses for disputes to be filed.</span>
                                        <span>&nbsp;If you choose to file a dispute, be sure to include the following information:</span>
                                        <br></br>
                                        <br></br>
                                        <ul>
                                        <li>Your full name</li>
                                        <li>Your date of birth</li>
                                        <li>Your Social Security Number</li>
                                        <li>Your current address</li>
                                        <li>Copy of a government issued ID (Such as a driver&rsquo;s license)</li>
                                        <li>The Account and account number that you dispute and all supporting data justifying your dispute</li>
                                        </ul>                   
                                        <br></br>                                                             
                                        <span>Contact addresses&nbsp;:</span>
                                        <br></br>               
                                        <br></br>                                                             
                                        <div className="row">
                                          <div className="col-3">
                                            Equifax:
                                          </div>   
                                          <div className="col-9">
                                            Equifax Information Services, LLC<br></br>
                                            P.O. Box 7404256<br></br>
                                            Atlanta, GA 30374-0256<br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-3">
                                            Experian:
                                          </div>   
                                          <div className="col-9">
                                            Experian Dispute Department<br></br>
                                            P.O. Box 9701<br></br>
                                            Allen, TX 75013<br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-3">
                                            Transunion:
                                          </div>   
                                          <div className="col-9">
                                            Transunion Consumer Solutions<br></br>
                                            P.O. Box 2000<br></br>
                                            Chester, PA 19022-2000<br></br>
                                          </div>
                                        </div>                           
                                        </Modal.Body>
                                  </Modal>
                                </div>
                            </div>                                                                                                                  
                            <div className="Spacing"></div>
                            <div className="row profile-top-bar profile-expanded-top-bar AddressBar">                                              
                              <div className="card-body" onClick={this.addressOpen}>
                                <div className="topHeaderInfoBlock">                                    
                                  <div className="container-fluid">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                      <span className="addressLeft">Address:</span>
                                      <span className="addressRight">{CurrentAddress.ZIPCODE ? "" + this.text_trim(CurrentAddress.STREETPREFIX + " " + CurrentAddress.STREETNAME + " " + CurrentAddress.STREETSUFFIX) : null}{", "}
                                      <wbr></wbr>{" "}
                                      {CurrentAddress.ZIPCODE ? CurrentAddress.CITY +", " +CurrentAddress.STATE +", " +CurrentAddress.ZIPCODE.substr(0, 5): null}
                                      <img
                                        alt="Address Open Icon"
                                        onClick={this.addressOpen}
                                        className={AddressVisible ? "arrowUp" : "arrowDown"}
                                        src={Arrow}
                                      ></img>                       
                                      </span>             
                                    </div>                                        
                                  </div>                                                                        
                                  <div className="">
                                    {AddressVisible ? (    
                                      <div className="col-12">
                                        <SlideDown className={"address-slidedown"}>
                                          <div>{this.RenderAddress(AddressList)}</div>
                                        </SlideDown>                                    
                                      </div>                                
                                    ) : null}
                                  </div>
                                </div>
                              </div>                              
                            </div>
                            <div className="Spacing"></div>
                            {RD && (
                                <div className="card shadow mb-4 table-content AccountsAccordion">
                                <Tabs
                                    activeKey={TabsActiveContent}
                                    className="nav nav-tabs nav-fill mainAccordion"
                                    onSelect={(index, label) => {
                                    this.setState({ TabsActiveContent: index,TradeLineSelected:-1 });
                                    }}
                                >
                                    <Tab
                                    eventKey="accounts"
                                    title="Accounts"                                    
                                    >
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                                      <div className="ClosedAccounts">
                                        <div className="ShowClosedAccounts">
                                          <table>
                                            <tr>
                                              <td>
                                                <span className="show_closed_accounts">Show Closed Accounts</span>
                                              </td>
                                              <td>                
                                                <Switch
                                                  onColor="rgb(102, 182, 214)"
                                                  className="switch_box"
                                                  style={{ transform: 'scale(0.8)' }}
                                                  onChange={this.PrivateItemChange}
                                                  checked={ShowPrivateItem}
                                                />
                                              </td>
                                            </tr>

                                          </table>
                          
                                        </div>                                        
                              
                                      </div>
                                      
                                      </div>                                      
                                    </div>
                                    
                                    <Accordion defaultActiveKey="1" activeKey={TradeLineSelected}>
                                        {AccountsFiltered.length ? 
                                        <div>
                                            <div className="row heading_row">
                                            <div className="col-4 text-left">
                                                <span className="mainLeftMargin">LENDER</span>
                                            </div>
                                            <div className="col-4 text-center">TYPE</div>
                                            <div className="col-4 text-right RightPaddingAccordion">
                                                BALANCE
                                            </div>
                                            </div>
                                            <TradeLineAccordion
                                            ResetOpenAccordion={ResetOpenAccordion}
                                            Accounts={AccountsFiltered}
                                            ChangeAccordion={this.ChangeAccordion}
                                            CurrentAddress={CurrentAddress}
                                            CurrentAccordionAccountsSelected={
                                                CurrentAccordionAccountsSelected
                                            }
                                            addressClose={this.addressClose}
                                            ></TradeLineAccordion>
                                        </div>
                                        : <div className="NoRecord">No Accounts To Display</div> }                        
                                    </Accordion>
                                    {this.state.noAccountsToShow ? <div className="NoRecord">No Accounts To Display</div> : null}                      
                                    <div className="loading_sign">
                                        <ScaleLoader
                                        sizeUnit={"px"}
                                        size={150}
                                        color={"#58b0d3"}
                                        loading={this.state.loading}
                                        />
                                    </div>
                                    </Tab>
                                    <Tab
                                    eventKey="inquiries"
                                    title="Inquiries"
                                    >
                                    <Accordion activeKey={TradeLineSelected}>
                                        {Inquiry.length ? 
                                        <div>
                                        <div className="row heading_row">
                                            <div className="col-4 text-left">
                                            <span className="mainLeftMargin">INQUIRER</span>
                                            </div>
                                            <div className="col-4 text-center">CATEGORY</div>
                                            <div className="col-4 text-right RightPaddingAccordion">
                                            INQUIRY DATE
                                            </div>
                                        </div>
                                        <InquiryAccordion
                                            Inquiry={Inquiry}
                                            addressClose={this.addressClose}
                                            ChangeAccordion={this.ChangeAccordion}
                                        ></InquiryAccordion>
                                        </div> 
                                        : 
                                        <div className="NoRecord">No Inquiries To Display</div>}                        
                                    </Accordion>                      
                                    </Tab>
                                    <Tab
                                    eventKey="records"
                                    title="Records"
                                    >
                                    <Accordion activeKey={TradeLineSelected}>
                                        {PublicRecord.length ?
                                        <div>
                                            <div className="row heading_row">
                                            <div className="col-4 text-left">
                                            <span className="mainLeftMargin">Court Name</span>
                                            </div>
                                            <div className="col-4 text-center">Status</div>
                                            <div className="col-4 text-right RightPaddingAccordion">
                                            Status Date
                                            </div>
                                        </div>
                                        <PublicRecordAccordion
                                            PublicRecord={PublicRecord}
                                            addressClose={this.addressClose}
                                            CurrentAccordionRecordsSelected={PublicRecord}
                                            ChangeAccordion={this.ChangeAccordion}
                                        ></PublicRecordAccordion>
                                        </div>
                                        : <div className="NoRecord">No Records To Display</div> }                                              
                                    </Accordion>
                                    </Tab>
                                </Tabs>
                                </div>
                            )}
                        </div> : null }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AccountProfile);