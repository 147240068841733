import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { isValidEmail, minLength } from "../utils/helpers";
import CMNavbar from "./CMNavbar";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      newPassword: "",
      apiError: "",
      emailError: "",
      changePassword: null,
      passwordError: "",
      passwordUpdated: false
    };
  }

  onEmailChange = e => {
    const email = e.target.value;
    this.setState({ email, emailError: isValidEmail(email) ? "" : "Email entered is invalid" });
  };

  forgotPassword = () => {
    if (!this.state.emailError) {
      Auth.forgotPassword(this.state.email)
        .then(response => {
          this.setState({ changePassword: true });
          console.log(response);
        })
        .catch(error => {
          this.setState({ apiError: error.message });
        });
    }
  };

  renderForgotPasswordForm() {
    return (
      <div>
        <CMNavbar/>      
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div> 
        <div className="formContainer">
          <div className="row title justify-content-center">Forgot Password</div>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <label>Email</label>
                <input
                  type="text"
                  className="inputField"
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  placeholder="Enter Email"
                />

                {this.state.emailError && <div className="row justify-content-center error">{this.state.emailError}</div>}
              </div>
            </div>

            <div className="row">&nbsp;</div>

            {this.state.apiError && <div className="row justify-content-center error">{this.state.apiError}</div>}

            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center">
                <input type="btn" className="btn btn-primary" value="Reset Password" onClick={this.forgotPassword} />
              </div>
            </div>
          </div>
        </div>        
    );
  }

  onChangeOTP = e => {
    this.setState({ otp: e.target.value });
  };

  onChangePassowrd = e => {
    const password = e.target.value;
    this.setState({
      newPassword: password,
      passwordError: minLength(password) ? "" : "Password should be atleast of 8 characters"
    });
  };

  updatePassword = () => {
    Auth.forgotPasswordSubmit(this.state.email, this.state.otp, this.state.newPassword)
      .then(response => {
        this.setState({ passwordUpdated: true });
      })
      .catch(error => {
        this.setState({ passwordUpdated: false, apiError: error });
      });
  };

  renderChangePasswordForm() {
    return (
      <div className="formContainer">
        <div className="row title justify-content-center">Reset Password</div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <label>OTP</label>
            <input type="number" className="inputField" value={this.state.otp} onChange={this.onChangeOTP} />
          </div>
        </div>

        <div className="row">&nbsp;</div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <label>Password</label>
            <input
              type="password"
              className="inputField"
              value={this.state.password}
              onChange={this.onChangePassowrd}
              placeholder="Enter New Password"
            />
            {this.state.passwordError && (
              <div className="row justify-content-center error">{this.state.passwordError}</div>
            )}
          </div>
        </div>

        <div className="row">&nbsp;</div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center">
            <input type="btn" className="btn btn-primary" value="Update Password" onClick={this.updatePassword} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.passwordUpdated) {
      return (
        <div className="formContainer">
          <div className="row justify-content-center">Password has been updated successfully. Continue to Login.</div>

          <div className="row">&nbsp;</div>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center">
              <input
                type="btn"
                className="btn btn-primary"
                value="Sign In"
                onClick={() => this.props.history.push("/signin")}
              />
            </div>
          </div>
        </div>
      );
    }
    return this.state.changePassword ? this.renderChangePasswordForm() : this.renderForgotPasswordForm();
  }
}

export default withRouter(ForgotPassword);
