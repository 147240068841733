import React, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel'
const index = 0;
const setIndex = 0;

class Testimonials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			index : 0
		}
	}
	handleSelect = (selectedIndex, e) => {
		console.log(selectedIndex);
		this.setState({
			index : selectedIndex
		})
	}
	render() {
		const {index} = this.state;	
		return (
			<section id="testimonials">
	            <div className="container">
	                <div className="">
	                    <h2 className="text-center">Customers <span>Reviews</span></h2> 
						<Carousel controls={false} id="carousel-example-generic" className="carousel slide" data-ride="carousel" activeIndex={index} onSelect={this.handleSelect}>
							<Carousel.Item>
							<div className="row">
                                    <div className="col-lg-5">
                                        <h3>Same Score For Buying A Home!</h3>
                                        <img src="assets/img/quote-icon.png" width="102" height="79" alt=""/> 
                                    </div>
                                    <div className="col-lg-7">
                                        <p><em>“Finally monitoring that matches up with what the lender has. Never felt more prepared for buying a house.”</em></p>
                                        <div className="avatar-img">	
                                            
                                        </div>
                                        <h4>Robert S</h4>
                                        <h6></h6>
                                    </div>
                            	</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className="row">
									<div className="col-lg-5">
										<h3>The Credit Monitoring Aligned With Life Goals</h3>
										<img src="assets/img/quote-icon.png" width="102" height="79" alt=""/> 
									</div>
									<div className="col-lg-7">
										<p><em>“I never knew that the car dealership used a different score than I could ever even monitor.”</em></p>
										<div className="avatar-img">	
										</div>
										<h4>Jane R</h4>
										<h6></h6>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className="row">
									<div className="col-lg-5">
										<h3>The Credit Monitoring Aligned With Life Goals</h3>
										<img src="assets/img/quote-icon.png" width="102" height="79" alt=""/> 
									</div>
									<div className="col-lg-7">
										<p><em>“Multiple scoring models! As a consumer I felt in the dark about my credit and my “Credit Score” until I became a member of CreditMaestro.”</em></p>
										<div className="avatar-img">	
										</div>
										<h4>Kory R</h4>
										<h6></h6>
									</div>
								</div>
							</Carousel.Item>
						</Carousel>            
					</div>
	            </div>
	        </section>
			);
	}
}

export default Testimonials;