import axios from 'axios';
import config from "../config";
import { Auth } from "aws-amplify";
import { getToken } from "../utils/helpers";

const api = axios.create({
    baseURL: config.credit_maestro.AMAZON_URL + '/' + config.credit_maestro.STAGE + '/',
    headers: {
        'Content-Type': 'application/json',
    }
});
api.interceptors.request.use(
    (config) => {        
        let token = getToken();     
        config.headers.Authorization = token;
        return config;
    },
    (error) => {        
        return Promise.reject(error);
    },
);
api.interceptors.response.use(
    (response) => {        
        return response.data;
    },
    (error) => {        
        const generic_error = 'Something went wrong.';
        if (error.response && error.response.data) {
            if (typeof error.response.data === 'string') {
                const newData = {
                    error: generic_error,
                    info: error.response.data,
                };
                return Promise.reject(newData);
            }
            return Promise.reject(error.response);
        }
        if (error.message === 'Network Error') {
            if (window.location.hash != '#/signin') {
                localStorage.clear();
                window.location.href = '/#/signin';                
            }            
        }
        return Promise.reject(error.message);
    },
);
export default api;
