import React from "react";
import { Auth } from "aws-amplify";
import { scoreData, getAdverseActions, postCreditScoreChange,scoreSummaryData } from "../api/index";
import { withRouter } from "react-router-dom";
import {  Modal } from "react-bootstrap";
import CMNavbar from "./CMNavbar";
import SideMenu from "./SideMenu";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Select from "react-select";
import ReactSpeedometer from "react-d3-speedometer"
import "react-slidedown/lib/slidedown.css";
import LoadingScreen from "./LoadingScreen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk,faInfo,faThumbsDown,faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import { insert,HelpMeScoreModelDropdown,ScoreHelpDropdown,MonitorMyCreditMappingScore,
         MonitorMyCreditMapping,GetGraphMapping,renderAdverseIcon,FrontendScoreMapping,
         renderAdverseType,getGraphData,GraphNameMapping,BackendScoreMapping,ScoreBreakMapping } from "../utils/helpers";
import NumberFormat from "react-number-format";
import { Redirect } from 'react-router'
import disableScroll from 'disable-scroll';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.selectPie = this.selectPie.bind(this);
    this.unSelectPie = this.UnSelectPie.bind(this);
    this.state = {
      ScoreData: [],
      AddressList: [],
      CurrentAddress: [],
      NoScoreReturned: null,
      ScoreChangeLimitReached:null,
      ScoreSelected: { value: "vantage_score_3", label: "VantageScore 3.0" },
      ScoreSelectedTemp: null,
      PreviousScoreSelectedTemp: null,
      ScoreHelpSelected : null,
      ScoreSummaryData : [],
      userData : [],
      ProcessedDate:null,
      loading: true,
      pageLoading: true,
      adverseActionLoading : true,
      userName: "",
      AdverseActions: [],
      ModelShow: false,
      HelpMeChooseModal : false,
      ScoreModelChangeConfirm : false,
      ModelData: "",
      RiskModelArray: [],
      Score: 0,
      ScoreModel : "vantage_score_3",
      GraphTitle: "VantageScore",
      TabsActiveContent: "accounts",
      CurrentAccordionAccountsSelected: -1,
      CurrentAccordionRecordsSelected: -1,
      RD: [],
      internalChart: [],
      isPopoverOpen : false,
      NavStatus : false
    };
    window.scrollTo(0, 0);
    disableScroll.off();
    const that = this;
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser({bypassCache: true}).then((data) => {      
      let DataAttributes = data.attributes;
      
      let scoreModel = DataAttributes['custom:score_model'];
      let BadData = DataAttributes['custom:bad_data'];
      let Retry = DataAttributes['custom:retry'];
      let GivenName = DataAttributes['given_name'];
      let scoreModelFrontendKey = FrontendScoreMapping[scoreModel];
      let scoreModelFrontendValue = GraphNameMapping[scoreModelFrontendKey];    
      if(typeof GivenName === 'undefined'){
        this.props.history.push("/signup");
      }
      if(Retry > 2){
        this.props.history.push("/account-blocked");
      }else{
        if(BadData == 1){
          this.props.history.push("/bad-ssn");
        }
        if(BadData == 2){
          this.props.history.push("/processing");
        }       
      }      
      if(BadData == 4){
        this.setState({ NoScoreReturned : true });
      }      
      this.setState({
        pageLoading : false,
        userData : data,
        ScoreModel : scoreModel,
        ScoreSelected: { value: scoreModelFrontendKey, label: scoreModelFrontendValue },
      });
      this.getAdverseAction(scoreModel);
      const chartsOptionsData = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie"
        },
        title: {
          text: ""
        },
        tooltip: {
          pointFormat: ""
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
              style: {
                fontFamily: "'Rubik', sans-serif",
                lineHeight: "26px",
                fontSize: "16px",
                color: "#848ea4"
              },
              format:
                ''
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Brands",
            colorByPoint: true,
            innerSize: "70%",
            data: getGraphData(this.state.ScoreSelected.value)
          }
        ]
      };
      this.setState({ chartOptions: chartsOptionsData });
    });

    this.GetScoreSummaryData();
    this.GetScoreData();
    
  }

  UnSelectPie = (i) => {
    if (isNaN(i)) {
    } else {
      let final_array = [];
      let score_type = this.state.ScoreSelected.value;
      if(GetGraphMapping[score_type] == 'fico'){      
        final_array = [0,1,2,3,4];
      }else{
        final_array = [0,1,2,3,4,5];
      }      
      final_array.map((item, key) => {        
        let point2 = this.state.internalChart.series[0].data[item];
        point2.setState('normal');
      });
      // let point = this.state.internalChart.series[0].data[i];      
      // point.select(false);            
      this.state.internalChart.redraw(true);
      this.state.internalChart.tooltip.hide();
    }
  };
  selectPie = (i) => {
    if (isNaN(i)) {
    } else {      
      let final_array = [];
      let score_type = this.state.ScoreSelected.value;
      if(GetGraphMapping[score_type] == 'fico'){
        final_array = [0,1,2,3,4];
      }else{
        final_array = [0,1,2,3,4,5];
      }      
      delete final_array[i];
      final_array.map((item, key) => {        
        let point2 = this.state.internalChart.series[0].data[item];
        point2.setState('inactive');
      });
      let point = this.state.internalChart.series[0].data[i];
      point.setState('hover');
      this.state.internalChart.tooltip.refresh(point);      
    }
  };
  
  GetScoreSummaryData = (response) =>{
    scoreSummaryData().then(response => {
      this.setState({
        ScoreSummaryData:response
      });
    });
    
  }
  GetScoreData = () => {
    let max_retry = 0;   
    let get_score_processing = 0;    
    let timerId = setInterval(() =>{      
      if(get_score_processing == 0){
        get_score_processing = 1;
        scoreData().then(response => {
          let ProcessedDateUnix = response.Date;          
          let ProcessedDate = new Date(ProcessedDateUnix*1000);
          this.setState({
            ProcessedDate : ProcessedDate.toLocaleDateString("en-US")
          });
          get_score_processing = 0;
          const RD = response;
          clearInterval(timerId);
          if (RD) {
            if (RD.RiskModel) {
              let RiskArray = [];
              RD.RiskModel.forEach((key, value) => {
                RiskArray[key.ModelIndicator] = key.Score;
              });              
              this.setState({
                RiskModelArray: RiskArray,
                Score: RiskArray[this.state.ScoreModel] ? parseInt(RiskArray[this.state.ScoreModel].substr(1, 4)) : null,
              });
            }
            if (RD.TradeLine) {
              this.setState({ Accounts: RD.TradeLine });
            }
            if (RD.Inquiry) {
              this.setState({ Inquiry: RD.Inquiry });
            }
            if (RD.PublicRecord) {
              this.setState({ PublicRecord: RD.PublicRecord });
            }
            if (RD.CurrentAddress) {
              const SDCurrentAddress = RD.CurrentAddress;
              this.setState({ CurrentAddress: SDCurrentAddress });
            }
            this.setState({ loading: false, RD: RD });
          }
        });
        max_retry++;
      }
      if(max_retry > 5){
        clearInterval(timerId);
      }
    },1000);
  };
  afterChartCreated = (chart) => {
    this.setState({
      internalChart : chart
    })
  }
  capitalizeFirstLetter = (string) => {
    if (typeof(string) != "undefined"){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
  renderPieGraph = () => {
    
    const { chartOptions, GraphTitle } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-12 text-center GraphTitle">{this.capitalizeFirstLetter(GetGraphMapping[this.state.ScoreSelected.value])}Score<span className="score_reg">®</span></div>
        </div>
        <br></br>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} callback={this.afterChartCreated}/>
      </div>
    );
  }
  hoverOn = (ContributionBucket) => {
    let score_type = this.state.ScoreSelected.value;        
    if(GetGraphMapping[score_type] == 'fico'){
      switch (ContributionBucket.toLowerCase()) {
        case "payment history":
          this.selectPie(0);
          return;        
        case "length of credit history":
          this.selectPie(1);
          return;
        case "credit mix":
          this.selectPie(2);
          return;
        case "amounts owed":
          this.selectPie(3);
          return;
        case "new credit":
          this.selectPie(4);
          return;        
      }
    }
    if(GetGraphMapping[score_type] == 'vantage'){
      switch (ContributionBucket.toLowerCase()) {
        case "available credit":
          this.selectPie(0);
          return;          
        case "payment history":
          this.selectPie(1);
          return;
        case "utilization":
          this.selectPie(2);
          return;
        case "balances":
          this.selectPie(3);
          return;
        case "depth of credit":
          this.selectPie(4);
          return;
        case "recent credit":
          this.selectPie(5);
          return;
      }
    }
  }
  hoverOff = (ContributionBucket) => {
    let score_type = this.state.ScoreSelected.value;        
    if(GetGraphMapping[score_type] == 'fico'){    
      switch (ContributionBucket.toLowerCase()) {
        case "payment history":
          this.unSelectPie(0);
          return;        
        case "length of credit history":
          this.unSelectPie(1);
          return;
        case "credit mix":
          this.unSelectPie(2);
          return;
        case "amounts owed":
          this.unSelectPie(3);
          return;
        case "new credit":
          this.unSelectPie(4);
          return;        
      }
    }
    if(GetGraphMapping[score_type] == 'vantage'){
      switch (ContributionBucket.toLowerCase()) {
        case "available credit":
          this.unSelectPie(0);
          return;          
        case "payment history":
          this.unSelectPie(1);
          return;
        case "utilization":
          this.unSelectPie(2);
          return;
        case "balances":
          this.unSelectPie(3);
          return;
        case "depth of credit":
          this.unSelectPie(4);
          return;
        case "recent credit":
          this.unSelectPie(5);
          return;
      }
    }  
  }
  handleClose = () => {
    this.setState({
      ModelShow: false,
      HelpMeChooseModal: false,
      ScoreModelChangeConfirm: false,
      NoScoreReturned: false,
      ScoreChangeLimitReached:false
    });
  }
  clickAdvancedAA = (ModelHeading, ModelText)  => {
    this.setState({ ModelData: ModelText, ModelTitle: ModelHeading });
    this.setState({ ModelShow: true });
  }
  getAdverseAction = (scoreType) => {
    let adverse_action_max_retry = 0;       
    let adverse_action_executing = 0;
    let adverseActiontimerId = setInterval(() =>{      
      if(adverse_action_executing == 0){        
        const data = {
          ScoreType: scoreType
        }; 
        adverse_action_executing = 1;
        getAdverseActions(data).then(response => {        
          let final_array = response.slice(0, 4);
          this.setState({
            adverseActionLoading : false
          });
          if(final_array.length == 4){
            clearInterval(adverseActiontimerId);
          }
          adverse_action_executing = 0;
          this.setState({ AdverseActions: final_array});
        });
        adverse_action_max_retry++;
        if(adverse_action_max_retry > 2){
          clearInterval(adverseActiontimerId);
        }
      }      
    },1000);

  }
  renderAdverseActionsList = () => {      
    
    const AA = this.state.AdverseActions;
    const { ModelData, ModelTitle } = this.state;
    
    if(AA.length){
      return (
        <div>
            <b className="note_stating">Note Stating:</b> Adverse actions are listed based on impact to your score
            {AA.map((item, key) => (
              <div key={key}>
                <div
                  onMouseEnter={() => this.hoverOn(item.ContributionBucket)}
                  onClick={() =>
                    this.clickAdvancedAA(item.ContributionBucket, item.FullDescription)
                  }
                  onMouseLeave={() => this.hoverOff(item.ContributionBucket)}
                  className={
                    renderAdverseType(item.ContributionBucket) + " adverse-content"
                  }
                >
                  <img
                    alt="Adverse Icon"
                    src={
                      "assets/img/" + renderAdverseIcon(item.ContributionBucket)
                    }
                    className="adverse_action_icon"
                  />
                  <div className="reason_statement">{item.ReasonStatement}</div>
                </div>
                <Modal show={this.state.ModelShow} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{ModelTitle}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{ModelData}</Modal.Body>
                </Modal>
              </div>
            ))}
        </div>
      )    
    }else{
      return (<div className="emptyAdverseAction">
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 NothingToShow">
              <FontAwesomeIcon icon={faThumbsDown} className="thumbsDown" />
            </div>
        </div>
        
          No Adverse Actions to Show
      </div>)
    }
  }
  helpMeChoose = () => {
    alert("A");
  }
  handleScoreChange = (e) => {    
    if(e.value == 'help_me_score'){
      this.setState({HelpMeChooseModal : true });
    }else{
      this.setState({
        ScoreModelChangeConfirm : true,
        PreviousScoreSelectedTemp : this.state.ScoreSelected,
        ScoreSelectedTemp : e
      });
    }
    
  }
  handleScoreModelChangeConfirmed = (e) => {
    
    this.setState({
      ScoreSelected : this.state.ScoreSelectedTemp,
      ScoreModelChangeConfirm: false
    });
    let data = {
      "ScoreType" : BackendScoreMapping[this.state.ScoreSelectedTemp.value]
    }
    postCreditScoreChange(data).then(response => {
      if(response.Status){
        this.props.history.push('/processing');
      }else{
        this.setState({ScoreChangeLimitReached : true,  ScoreSelected : this.state.PreviousScoreSelectedTemp});
      }      
    });        
  } 
  handleScoreHelpChange = (e) => {    
    this.setState({      
      ScoreHelpSelected: e,
      // ScoreSelected : null
    })
  }
  navigationToProfileLink = (link,RecordsToShow) => {    
    this.props.history.push({
      pathname:"/account-profile",
      state:{
        TabsActiveContent: link,
        RecordsToShow : RecordsToShow
       }
    });
  }     
  onSideMenuChange = (NavStatus) => {
    
    if(NavStatus){
      disableScroll.on();
    }else{
      disableScroll.off();
    }
    this.setState({
      NavStatus : NavStatus
    })  
  }
  render() {
    const {
      AddressVisible,
      CurrentAddress,   
      ProcessedDate,   
      ScoreSelected,
      AddressList,
      Score,      
      RD,
      pageLoading,      
      ScoreHelpSelected,
      ScoreSummaryData,
      NavStatus
    } = this.state;  
    let LoanTotal = ScoreSummaryData.PersonalLoanTotal+ScoreSummaryData.CreditCardLoanTotal+ScoreSummaryData.AutoLoansTotal+ScoreSummaryData.RealEstateTotal+ScoreSummaryData.OtherLoansTotal; 
    let ScoreCountMapping = ScoreBreakMapping[this.state.ScoreSelected.value];
    let ScoreMappingBreaks = [];
    let ScoreMappingColors = [];
    let MinScore = 0;
    let MaxScore = 0;
    if(ScoreCountMapping == 1){
      MinScore = 250;
      MaxScore = 900;
      ScoreMappingBreaks = [250,490,574,639,729,900];
      ScoreMappingColors = ['#C24C48','#CF7544','#DDD42F','#B7B844','#93B04A'];
    }
    if(ScoreCountMapping == 2){
      MinScore = 300;
      MaxScore = 850;
      ScoreMappingBreaks = [300,499,600,660,780,850];
      ScoreMappingColors = ['#C24C48','#CF7544','#DDD42F','#B7B844','#93B04A'];
    }
    if(ScoreCountMapping == 3){
      MinScore = 300;
      MaxScore = 850;
      ScoreMappingBreaks = [300,549,619,739,850];
      ScoreMappingColors = ['#C24C48','#CF7544','#DDD42F','#B7B844','#93B04A'];
    }

    return (
      <div className="Dashboard MainPage">
        <CMNavbar onSideMenuChange={this.onSideMenuChange} anonymous={false} />
        <div className="navbarSpace"></div>
        <div className="row">
          <div className="col-12">
            <div className={"leftSidebar "+ (NavStatus ? "OpenMobile" : "CloseMobile d-none d-sm-block")}>
              <SideMenu />
            </div>
            {pageLoading == true ? <div><LoadingScreen/></div> : null}
            {pageLoading == false ? <div className="Content">
              <div className="row profile-top-bar profile-expanded-top-bar">                
                <Modal show={this.state.ScoreChangeLimitReached} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Score Change Limit Reached</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    You have reached your monthly score change limit, please try again next month
                  </Modal.Body>
                </Modal>
                <Modal show={this.state.NoScoreReturned} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>No Score Returned</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Since the score you selected didn't returned any data, You are allowed to select a new score. 
                  </Modal.Body>
                </Modal>
                
                <Modal show={this.state.HelpMeChooseModal} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>What are you looking to do:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Select
                      className="scoreModelSelect"
                      value={ScoreHelpSelected}
                      onChange={this.handleScoreHelpChange}
                      options={ScoreHelpDropdown}
                    />
                    <div>
                      <div className="row">&nbsp;</div>                      
                    </div>
                    <div>
                      {ScoreHelpSelected ? MonitorMyCreditMapping[ScoreHelpSelected.value] : null}
                    </div>
                    <div>
                      <div className="row">&nbsp;</div>                      
                    </div>
                    {ScoreHelpSelected ? <Select
                      className="scoreModelSelect"
                      value={ScoreSelected}
                      onChange={this.handleScoreChange}
                      options={MonitorMyCreditMappingScore[ScoreHelpSelected.value]}
                    /> : null}                    
                  </Modal.Body>
                </Modal>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">                
                  <Modal show={this.state.ScoreModelChangeConfirm} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>How many times I can do this ? </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="ScoreModel">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            You change the score you are monitoring once every month, and then you can change again the following month.
                          </div>
                        </div>                      
                        <div class="row">&nbsp;</div>
                        <div class="row">&nbsp;</div>
                        <div class="row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <input className="btn ContinueButton" onClick={this.handleScoreModelChangeConfirmed} value="Continue"></input>                      
                          </div>
                          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                            <input type="submit" onClick={this.handleClose} class="btn CancelButton" value="Cancel"></input>
                          </div>
                        </div>
                    </Modal.Body>
                  </Modal>
                  <div className="card h-100">
                    
                    <div className="topHeaderSelectBox">      
                      <div className="row">
                        <div className="ScoreModelBlock">
                          <ReactTooltip />
                          <div className="scoreModelLabel">Score Model&nbsp;<FontAwesomeIcon data-class="scoreModelPopUp" data-multiline="true" data-place="bottom" data-tip="Lenders have the option to utilize any scoring <br>model that they assessed and deemed appropriate.<br>If you know what scoring model your particular lender uses, select that. <br>Otherwise use our 'Help me Choose' tool in the drop down." onClick={this.helpMeChoose} icon={faAsterisk} className="ScoreModel"></FontAwesomeIcon></div>
                          <Select
                            className="scoreModelSelect"
                            value={ScoreSelected}
                            onChange={this.handleScoreChange}
                            options={HelpMeScoreModelDropdown}
                          />
                        </div>
                      </div>                          
                    </div>
                    
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row">
                          <div className="ScoreModelGuage">
                            {Score > 0 ? <ReactSpeedometer
                              minValue={MinScore}
                              maxValue={MaxScore}
                              value={Score}                              
                              height={180}
                              customSegmentStops={ScoreMappingBreaks}
                              segmentColors={ScoreMappingColors}
                            /> : <div></div> }
                          </div>                              
                      </div>
                    </div>           
                    {Score > 0 ? 
                    <div className="row">
                      <div className="col-12 text-center">
                         Note: The score and corresponding report are up to date as of { ProcessedDate }
                      </div>
                    </div> : <div></div> }      
                  </div>
                              
                </div>  

              </div> 
              <div className="Spacing"></div>
              <div className="row profile-top-bar profile-expanded-top-bar DashboardSummary">                
                  <div className="container-fluid">
                      <div className="row">
                        <div className="AccountBreakdown">
                          Account Breakdown
                        </div>
                      </div>
                      <div className="row SummarySpacing">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="OpenAccounts">
                            <div className="row Loan">
                              <div className="col-10">                        
                                <div className="LoanSummary">          
                                  <a className="cursor" onClick={() => this.navigationToProfileLink('accounts','open')}>     
                                    Open Accounts<FontAwesomeIcon icon={faChevronRight} className="faChevronRight" />
                                  </a>
                                </div>                        
                              </div>
                              <div className="col-2 text-right">
                                <div className="LoanSummaryValue">
                                  {ScoreSummaryData.OpenAccountsTotal}
                                </div>
                              </div>
                            </div>
                          </div>                    
                        </div>
                
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="ClosedAccounts">
                            <div className="row Loan">
                              <div className="col-10">                        
                                <div className="LoanSummary">
                                  <a className="cursor" onClick={() => this.navigationToProfileLink('accounts','closed')}>     
                                  Closed Accounts<FontAwesomeIcon icon={faChevronRight} className="faChevronRight" />
                                  </a>
                                </div>                        
                              </div>
                              <div className="col-2 text-right">
                                <div className="LoanSummaryValue">
                                  {ScoreSummaryData.ClosedAccountsTotal}
                                </div>
                              </div>
                            </div>
                          </div>                    
                        </div>
                      </div>      
                      

                        
                      <div className="row SummarySpacing">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="InquiryTotal">
                            <div className="row Loan">
                              <div className="col-10">                        
                                <div className="LoanSummary">
                                  <a className="cursor" onClick={() => this.navigationToProfileLink('inquiries','all')}>     
                                    Inquiry Total<FontAwesomeIcon icon={faChevronRight} className="faChevronRight" />
                                  </a>
                                </div>                        
                              </div>
                              <div className="col-2 text-right">
                                <div className="LoanSummaryValue">
                                  {ScoreSummaryData.InquiryTotal}
                                </div>
                              </div>
                            </div>
                          </div>                    
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="PublicRecordCount">
                            <div className="row Loan">
                              <div className="col-10">                        
                                <div className="LoanSummary">
                                  <a className="cursor" onClick={() => this.navigationToProfileLink('records','all')}>     
                                    Public Record<FontAwesomeIcon icon={faChevronRight} className="faChevronRight" />
                                  </a>
                                </div>                        
                              </div>
                              <div className="col-2 text-right">
                                <div className="LoanSummaryValue">
                                  {ScoreSummaryData.PublicRecordTotal}
                                </div>
                              </div>
                            </div>
                          </div>                    
                        </div>
                      </div>      


                      <hr></hr>
                      <div className="row">
                        <div className="BalanceBreakdown">
                          Balance Breakdown
                        </div>
                      </div>
                      
                      <div class="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 LoanLabel">
                          Real Estate Loan
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                          <div className="LoanValue">
                            {!isNaN(ScoreSummaryData.RealEstateTotal) ? 
                            <NumberFormat
                              value={ScoreSummaryData.RealEstateTotal}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                              renderText={value => <div>{value}</div>}
                            /> : ScoreSummaryData.RealEstateTotal}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 LoanLabel">
                          Personal Loan
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                          <div className="LoanValue">
                            {!isNaN(ScoreSummaryData.PersonalLoanTotal) ? 
                            <NumberFormat
                              value={ScoreSummaryData.PersonalLoanTotal}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                              renderText={value => <div>{value}</div>}
                            /> : ScoreSummaryData.PersonalLoanTotal}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 LoanLabel">
                          Credit Cards 
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                          <div className="LoanValue">
                            {!isNaN(ScoreSummaryData.CreditCardLoanTotal) ? 
                              <NumberFormat
                                value={ScoreSummaryData.CreditCardLoanTotal}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                                renderText={value => <div>{value}</div>}
                              /> : ScoreSummaryData.CreditCardLoanTotal}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 LoanLabel">
                          Auto Loan 
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                          <div className="LoanValue">
                            {!isNaN(ScoreSummaryData.AutoLoansTotal) ? 
                              <NumberFormat
                                value={ScoreSummaryData.AutoLoansTotal}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                                renderText={value => <div>{value}</div>}
                              /> : ScoreSummaryData.AutoLoansTotal}
                          </div>
                        </div>
                      </div>
     
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 LoanLabel">
                        Other Loan
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                          <div className="LoanValue">
                          {!isNaN(ScoreSummaryData.OtherLoansTotal) ? 
                                    <NumberFormat
                                      value={ScoreSummaryData.OtherLoansTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$ "}
                                      renderText={value => <div>{value}</div>}
                                    /> : ScoreSummaryData.OtherLoansTotal}
                          </div>
                        </div>
                      </div>     
                      <div className="Spacing"></div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right LoanTotal">     
                            {!isNaN(LoanTotal) ? 
                              <NumberFormat
                                value={LoanTotal}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                                renderText={value => <div>{value}</div>}
                              /> : LoanTotal}                                                          
                        </div>
                      </div> 
                  </div>                              
              </div>
              <div className="Spacing"></div>
              {RD && (
                <div className="row profile-top-bar profile-expanded-top-bar DashboardSummary">
                  <div className="col-12 adverse-action">
                    <div className="row">
                      <div className="col-lg-12 what_makes_up_my_score">
                        What Makes Up My Score
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <p>
                          The score models are different, and different attributes
                          are driving where your score ends up.
                        </p>
                        <div className="chart-section">
                          <div className="">
                            <div className="">
                              <div id="chartContainer">
                                {this.renderPieGraph()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {!this.state.adverseActionLoading ? <div class="adverse_actions">Adverse Actions:</div> : null}
                        {this.state.adverseActionLoading ? <div><LoadingScreen/></div> : this.renderAdverseActionsList()}                      
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div> : null }
            <div className="Spacing"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Dashboard);
