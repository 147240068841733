import api from './api';
const scoreData = () => api.post('/score/get');
const scoreSummaryData = () => api.post('/score/get-summary');

const profilePicture = (data) => api.post('/profile/picture', data);
const getProfilePicture = () => api.get('/profile/picture');
const getAdverseActions = (data) => api.post('/adverseactions', data);
const postStripeData = (data) => api.post('/stripe/handle', data);
const postStripeSessionData = (data) => api.post('/stripe/session', data);
const postStripeUpdateCardSessionData = () => api.post('/stripe/update-card');

const getSubscriptionData = () => api.post('/stripe/get/subscription')

const postStripeCancel = () => api.post('/stripe/cancel');
const postStripeResume = () => api.post('/stripe/resume');

const getFaqData = (data) => api.get('/faq-data', data);
const getGlossaryData = (data) => api.get('/glossary-data', data);
const postSSNUpdate = (data) => api.post('/profile/ssn/update', data);
const getZipData = (data) => api.post('/verify-zip', data);
const postCreditScoreChange = (data) => api.post('/score/change', data);
const postGetNotifications = () => api.post('/notifications/get');
const postReadNotifications = (data) => api.post('/notifications/read',data);

export {
    scoreData,
    scoreSummaryData,
    profilePicture,
    getProfilePicture,
    getAdverseActions,
    postStripeData,
    postStripeSessionData,
    postStripeUpdateCardSessionData,
    getFaqData,
    getGlossaryData,
    postSSNUpdate,
    getZipData,
    postCreditScoreChange,
    postStripeCancel,
    postStripeResume,
    getSubscriptionData,
    postGetNotifications,
    postReadNotifications
}

