import validator from "validator";
import { attr } from "highcharts";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const required = value => (value ? undefined : "Required");

const isValidEmail = value => validator.isEmail(value);

const minLength = value => value.length > 6;

const passwordMatch = (value, values) => values.password !== values.passwordMatch && "Passwords must match";


const HelpMeScoreModelDropdown = [
  { value: 'help_me_score', label: 'Help me Choose'},
  {
    label: 'FICO',
    options: [
      { value: 'fico_classic_score_2', label: 'FICO Classic Score 2'},
      { value: 'fico_auto_score_2', label: 'FICO Auto Score 2'},
      { value: 'fico_classic_score_8', label: 'FICO Classic Score 8'},
      { value: 'fico_auto_score_8', label: 'FICO Auto Score 8'},
      { value: 'fico_bankcard_score_8', label: 'FICO Bankcard Score 8'},
      { value: 'fico_classic_score_9', label: 'FICO Classic Score 9'},
      { value: 'fico_auto_score_9', label: 'FICO Auto Score 9'},
      { value: 'fico_bankcard_score_9', label: 'FICO Bankcard Score 9'},
    ]
  },
  {
    label: 'Vantage',
    options: [
      { value: 'vantage_score_1', label: 'VantageScore 1.0'},
      { value: 'vantage_score_2', label: 'VantageScore 2.0'},
      { value: 'vantage_score_3', label: 'VantageScore 3.0'},
      { value: 'vantage_score_4', label: 'VantageScore 4.0'},
    ]
  },
];
const MonitorMyCreditMappingScore = {
  'monitor_my_credit' : [
    { value: 'fico_classic_score_8', label: 'FICO Classic Score 8'},
    { value: 'vantage_score_3', label: 'VantageScore 3.0'},
  ],
  'monitor_my_credit_as_i_am_looking_to_buy_a_car' : [
    { value: 'fico_classic_score_2', label: 'FICO Classic Score 2'},
    { value: 'fico_auto_score_2', label: 'FICO Auto Score 2'},
    { value: 'fico_classic_score_8', label: 'FICO Classic Score 8'},
    { value: 'fico_auto_score_8', label: 'FICO Auto Score 8'},
    { value: 'vantage_score_3', label: 'VantageScore 3.0'},
  ],
  'monitor_my_credit_as_i_am_looking_to_buy_a_house' : [
    { value: 'fico_classic_score_2', label: 'FICO Classic Score 2'},
  ],
  'monitor_my_credit_as_i_am_looking_to_open_credit_card' : [
    { value: 'fico_classic_score_8', label: 'FICO Classic Score 8'},    
    { value: 'fico_bankcard_score_8', label: 'FICO Bankcard Score 8'},
    { value: 'vantage_score_3', label: 'VantageScore 3.0'},
  ],
}
const GraphNameMapping = {
  'fico_classic_score_2' : 'FICO Classic Score 2',
  'fico_auto_score_2' : 'FICO Auto Score 2',
  'fico_classic_score_8' : 'FICO Classic Score 8',
  'fico_auto_score_8' : 'FICO Auto Score 8',
  'fico_bankcard_score_8' : 'FICO Bankcard Score 8',
  'fico_classic_score_9' : 'FICO Classic Score 9',
  'fico_auto_score_9' : 'FICO Auto Score 9',
  'fico_bankcard_score_9' : 'FICO Bankcard Score 9',
  'vantage_score_1' : 'VantageScore 1.0',
  'vantage_score_2' : 'VantageScore 2.0',
  'vantage_score_3' : 'VantageScore 3.0',
  'vantage_score_4' : 'VantageScore 4.0',
}
const FrontendScoreMapping = {
  'F' : 'fico_classic_score_2',
  'V' : 'fico_auto_score_2',
  'AF' : 'fico_classic_score_8',
  'AG' : 'fico_auto_score_8',
  'AJ' : 'fico_bankcard_score_8',
  'F9' : 'fico_classic_score_9',
  'FE' : 'fico_auto_score_9',
  'FF' : 'fico_bankcard_score_9',
  'Q' : 'vantage_score_1',
  'V2' : 'vantage_score_2',
  'V3' : 'vantage_score_3',
  'V4' : 'vantage_score_4',
}
const BackendScoreMapping = {
  'fico_classic_score_2' : 'F',
  'fico_auto_score_2' : 'V',
  'fico_classic_score_8' : 'AF',
  'fico_auto_score_8' : 'AG',
  'fico_bankcard_score_8' : 'AJ',
  'fico_classic_score_9' : 'F9',
  'fico_auto_score_9' : 'FE',
  'fico_bankcard_score_9' : 'FF',
  'vantage_score_1' : 'Q',
  'vantage_score_2' : 'V2',
  'vantage_score_3' : 'V3',
  'vantage_score_4' : 'V4',
}
const ScoreBreakMapping = {
  'fico_auto_score_2' : 1,
  'fico_auto_score_8' : 1,
  'fico_auto_score_9' : 1,
  'fico_bankcard_score_8' : 1,
  'fico_bankcard_score_9' : 1,

  'fico_classic_score_2' : 2,
  'fico_classic_score_8' : 2,
  'fico_classic_score_9' : 2,

  'vantage_score_1' : 3,
  'vantage_score_2' : 3,
  'vantage_score_3' : 3,
  'vantage_score_4' : 3,
  
}
const GetGraphMapping = {
  'fico_classic_score_2' : 'fico',
  'fico_auto_score_2' : 'fico',
  'fico_classic_score_8' : 'fico',
  'fico_auto_score_8' : 'fico',
  'fico_bankcard_score_8' : 'fico',
  'fico_classic_score_9' : 'fico',
  'fico_auto_score_9' : 'fico',
  'fico_bankcard_score_9' : 'fico',
  'vantage_score_1' : 'vantage',
  'vantage_score_2' : 'vantage',
  'vantage_score_3' : 'vantage',
  'vantage_score_4' : 'vantage',
}
const GraphTitleMapping = [
  { value: 'fico_classic_score_2', label: 'FICO Classic Score 2'},
  { value: 'fico_auto_score_2', label: 'FICO Auto Score 2'},
  { value: 'fico_classic_score_8', label: 'FICO Classic Score 8'},
  { value: 'fico_auto_score_8', label: 'FICO Auto Score 8'},
  { value: 'fico_bankcard_score_8', label: 'FICO Bankcard Score 8'},
  { value: 'fico_classic_score_9', label: 'FICO Classic Score 9'},
  { value: 'fico_auto_score_9', label: 'FICO Auto Score 9'},
  { value: 'fico_bankcard_score_9', label: 'FICO Bankcard Score 9'},
  { value: 'vantage_score_1', label: 'VantageScore 1.0'},
  { value: 'vantage_score_2', label: 'VantageScore 2.0'},
  { value: 'vantage_score_3', label: 'VantageScore 3.0'},
  { value: 'vantage_score_4', label: 'VantageScore 4.0'},
];
const MonitorMyCreditMapping = {
    'monitor_my_credit' : 'A generic risk scoring model will meet your needs; typically one of the following is utilized:',
    'monitor_my_credit_as_i_am_looking_to_buy_a_car' : 'Auto lenders have the option to utilize any scoring model of their choice; however, the following are used with higher regularity:',
    'monitor_my_credit_as_i_am_looking_to_buy_a_house' : 'Mortgage lenders are bound by more restriction on the scoring model they use, the followign score would be the best option to monitor:',
    'monitor_my_credit_as_i_am_looking_to_open_credit_card' : 'Credit card issuers have the option to utilize any scoring model of their choice; however, the following are used with higher regularity:',
};
const ScoreHelpDropdown = [
  { value: 'monitor_my_credit', label: 'Monitor my credit'},
  { value: 'monitor_my_credit_as_i_am_looking_to_buy_a_car', label: 'Monitor my credit as I am looking to buy a car'},
  { value: 'monitor_my_credit_as_i_am_looking_to_buy_a_house', label: 'Monitor my credit as I am looking to buy a house or refinance my existing house'},
  { value: 'monitor_my_credit_as_i_am_looking_to_open_credit_card', label: 'Monitor my credit as I am looking to open a new credit card'},
];
const insert = function insert(main_string, ins_string, pos) {
    if(typeof(pos) == "undefined") {
        pos = 0;
    }
    if(typeof(ins_string) == "undefined") {
        ins_string = '';
    }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
}
const getToken = function () {
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        var value = localStorage.getItem(key);
        if (key.match(/idToken.*/)) {
            return value;
        }
    }
}
const getUserAttributes = function (attribute,data) {
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        var value_to_return = "";
        var value = localStorage.getItem(key);
        if (key.match(/userData.*/)) {
            let attributes = JSON.parse(value).UserAttributes;            
            console.log("A");
            console.log(attribute);
            console.log(attributes);
            console.log("B");
            for (var i = 1; i < 12; i++){                      
                
                if (attributes[i]['Name'] == attribute) {
                    value_to_return =  attributes[i]['Value'];
                }                
            }
        }
        return value_to_return;
    }
}
const protectedRoute = function(){    
    Auth.currentAuthenticatedUser({
        bypassCache: true
    }).then(data => {     
        let data_attributes = data.attributes;
        let bad_data  = data_attributes['custom:bad_data'];
        if(bad_data == 2){
            window.location.href= '/#/processing';        
        }
        if(bad_data == 1){
            window.location.href= '/#/bad-data';        
        }        
    });
}
function renderAdverseIcon(ContributionBucket){
    switch (ContributionBucket.toLowerCase()) {
        case "payment history":
          return "payment_history.png"
        case "amounts owed":
          return "amount_owed.png"
        case "length of credit history":
          return "length_of_credit_history.png"
        case "recent credit":
          return "recent_credit.png"
        case "new credit":
          return "new_credit.png"
        case "credit mix":
          return "credit_mix.png"
        case "depth of credit":
          return "depth_of_credit.png"
        case "utilization":
          return "utilization.png"
        case "balances":
          return "balances.png"
        case "available credit":
          return "available_credit.png"          
    }
}  
function renderAdverseType(ContributionBucket){
    switch (ContributionBucket.toLowerCase()) {
      case "payment history":
        return "payment_history"
      case "amounts owed":
        return "amount_owed"
      case "length of credit history":
        return "length_of_credit_history"
      case "recent credit":      
        return "recent_credit"
      case "new credit":
        return "new_credit"
      case "credit mix":
        return "credit_mix"
      case "depth of credit":
        return "depth_of_credit"
      case "utilization":
        return "utilization"
      case "balances":
        return "balances"
      case "available credit":
        return "available_credit"
    }
}
function getGraphData(scoreType){
  console.log(scoreType);
    let ficoData = [];
    if (GetGraphMapping[scoreType] === "fico") {
      ficoData = [
        {
          name: "Payment History - 35%",
          y: 35,
          color: "#7A90A8"
        },
        {
          name: "Length of Credit History - 15%",
          y: 15,
          color: "#6B69F0"
        },
        {
          name: "Credit Mix - 10%",
          y: 10,
          color: "#0091FC"
        },
        {
          name: "Amount Owned - 30%",
          y: 30,
          color: "#00C1EA"
        },
        {
          name: "New Credit - 10%",
          y: 10,
          color: "#00E5C8"
        }
      ];
    }
    if (GetGraphMapping[scoreType] === "vantage") {
      ficoData = [
        {
          name: "Available Credit - 03%",
          y: 3,
          color: "#73e2c6"
        },
        {
          name: "Payment History - 40%",
          y: 40,
          color: "#7f8fa6"
        },
        {
          name: "Utilization - 20%",
          y: 20,
          color: "#6969e6"
        },
        {
          name: "Balances - 11%",
          y: 10,
          color: "#9a6fea"
        },
        {
          name: "Depth of Credit - 21%",
          y: 21,
          color: "#588ef5"
        },
        {
          name: "Recent Credit - 05%",
          y: 5,
          color: "#8bafec"
        }
      ];
    }
    return ficoData;
}
export { required, isValidEmail, minLength, passwordMatch, insert, getToken, getUserAttributes,protectedRoute,
  renderAdverseIcon,renderAdverseType,getGraphData,HelpMeScoreModelDropdown,ScoreHelpDropdown,FrontendScoreMapping,
  MonitorMyCreditMappingScore,MonitorMyCreditMapping,GraphTitleMapping,GraphNameMapping,GetGraphMapping,
  BackendScoreMapping,ScoreBreakMapping };

